import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const DattoDeviceDetails = ({ data }) => {
  const [showBody, setShowBody] = useState(true);

  return (
    <FalconComponentCard className="m-0">
      <Flex
        className="p-3 bg-100 w-100"
        onClick={() => setShowBody(!showBody)}
        role="button"
        justifyContent="between"
      >
        <h6 className="m-0">Dispositivo Datto RMM</h6>
        <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
      </Flex>
      {showBody && (
        <FalconComponentCard.Body>
          <Flex className="mb-3">
            <Button
              target="_blank"
              size="sm"
              as={Link}
              variant="primary"
              to={data.webRemoteUrl}
              className="me-0"
            >
              Acesso Remoto
            </Button>
            <Button
              target="_blank"
              size="sm"
              as={Link}
              variant="primary"
              to={data.portalUrl}
              className="ms-1"
            >
              Dispositivo
            </Button>
          </Flex>

          <Row>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">Hostname</p>
              <p className="fs-10">{data.hostname || "---"}</p>
            </Col>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">Sistema Operacional</p>
              <p className="fs-10">{data.operatingSystem || "---"}</p>
            </Col>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">Fabricante</p>
              <p className="fs-10">{data.manufacturer || "---"}</p>
            </Col>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">Modelo</p>
              <p className="fs-10">{data.model || "---"}</p>
            </Col>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">Processador</p>
              <p className="fs-10">{data.processor || "---"}</p>
            </Col>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">Número de Série</p>
              <p className="fs-10">{data.serialNumber || "---"}</p>
            </Col>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">Memória Física Total</p>
              <p className="fs-10">
                {data.totalPhysicalMemory
                  ? `${Math.ceil(data.totalPhysicalMemory / (1024 ** 3))} GB`
                  : "---"}
              </p>
            </Col>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">Site</p>
              <p className="fs-10">{data.siteName || "---"}</p>
            </Col>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">IP Interno</p>
              <p className="fs-10">{data.intIpAddress || "---"}</p>
            </Col>
            <Col xs={12} md={6}>
              <p className="fw-bold text-800 fs-10 mb-1">IP Externo</p>
              <p className="fs-10">{data.extIpAddress || "---"}</p>
            </Col>
          </Row>
        </FalconComponentCard.Body>
      )}
    </FalconComponentCard>
  );
};

export default DattoDeviceDetails;
