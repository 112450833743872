import { faCopy as regularCopy } from "@fortawesome/free-regular-svg-icons";
import { faPowerOff, faCopy as solidCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import DialogModal from "components/modals/DialogModal";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";

const Settings = () => {
  const api = useApi();

  const [generatedPassword, setGeneratedPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [turnOn, setTurnOn] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);

  useEffect(() => {
    getSettings();
  }, []);

  const generateToken = async () => {
    setCopied(false);
    const response = await api.mspTalksSettings.generateToken();

    if (typeof response === "string") {
      setGeneratedPassword(response);
    } else {
      setError(response.userMessage);
      setShowError(true);
    }
  };

  const handleDialog = response => {
    if (response) {
      if (turnOn)
        disableSettings();
      else
        turnOnSettings();
    }
    setShowConfirmMessage(false);
  };

  const getSettings = async () => {
    const response = await api.mspTalksSettings.find();
    if (response.id)
      setTurnOn(true);
    else
      setTurnOn(false);
  };

  const turnOnSettings = async () => {
    const response = await api.mspTalksSettings.create();

    if (response.id) {
      setSuccess("Integração habilitada com sucesso");
      setShowSuccess(true);
      setTurnOn(true);
    } else {
      setError(response.userMessage);
      setShowError(true);
    }
  };

  const disableSettings = async () => {
    const response = await api.mspTalksSettings.delete();

    if (response.ok) {
      setSuccess("Integração desabilitada com sucesso");
      setShowSuccess(true);
      setTurnOn(false);
    } else {
      setError(response.userMessage);
      setShowError(true);
    }
  };

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <DialogModal
        message={confirmMessage}
        showModal={showConfirmMessage}
        handleClose={() => setShowConfirmMessage(false)}
        response={handleDialog} />


      <Flex className="w-50" justifyContent="between" alignItems="center">
        <p className="fw-bold m-0">Integração MSP Talks</p>
        <Button
          className="me-2"
          onClick={() => {
            if (!turnOn) {
              setConfirmMessage("Você está prestes a habilitar a integração com o MSP Talks. Confirma esta ação?");
            } else {
              setConfirmMessage("Tem certeza que deseja desabilitar a integração com o MSP Talks?");
            }
            setShowConfirmMessage(true);
          }}>
          <FontAwesomeIcon icon={faPowerOff} className="me-2" />
          {!turnOn ? 'Habilitar integração' : 'Desabilitar integração'}
        </Button>
      </Flex>

      {turnOn &&
        <div className="mt-3">
          <p className="m-0">Gere uma senha e adicione-a ao atributo <code>token</code> do metadado do chatbot.</p>
          <p className="fs-10 m-0 mb-2">A senha gerada será mostrada apenas uma vez. Gerar uma nova senha impedirá que a antiga funcione.</p>
          <div className="w-50">
            {generatedPassword
              ? <>
                <div>
                  <strong className="flex-1">{generatedPassword}</strong>
                  <CopyToClipboard text={generatedPassword} onCopy={() => setCopied(true)}>
                    <Button variant="link" size="sm">
                      <FontAwesomeIcon icon={copied ? solidCopy : regularCopy} />
                    </Button>
                  </CopyToClipboard>
                </div>
              </>
              : <strong>**************************</strong>}

            <Button size="sm" className="mt-2" onClick={() => generateToken()}>Gerar</Button>

          </div>
        </div>
      }
    </>
  );
};

export default Settings;