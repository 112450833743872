import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import DialogModal from "components/modals/DialogModal";
import HolidayRegistrationModal from "components/modals/HolidayRegistrationModal";
import Pagination from "components/pagination";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const HolidayRegistration = () => {
  const api = useApi();
  const [showModal, setShowModal] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [selectedHoliday, setSelectedHoliday] = useState({});
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [holidays, setHolidays] = useState({
    content: [
      {
        date: '',
        description: ''
      }
    ]
  });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10
  });

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.holidays.list(queryParams);
    if (response.content) setHolidays(response);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const columns = [
    {
      accessorKey: 'date',
      header: 'Data',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'description',
      header: 'Descrição',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <button className="btn btn-link btn-sm" onClick={() => handleDelete(item)} disabled={item.isAdmin}>
            <FontAwesomeIcon icon={faTrash} size="sm" />
          </button>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: holidays.content,
    columns
  })

  const create = async body => {
    const response = await api.holidays.add(body);
    if (response.status) {
      setError(response);
      setShowAlertError(true);
    } else {
      getData();
      setSuccess("Feriado adicionado com sucesso");
      setShowAlertSuccess(true);
    }
    handleCloseModal();
  };

  const edit = async bodyChange => {
    const response = await api.holidays.edit(selectedHoliday.id, bodyChange);
    if (response.status) {
      getData();
      setSuccess("Feriado editado com sucesso");
      setShowAlertSuccess(true);
    } else {
      setError(response);
      setShowAlertError(true);
    }
    handleCloseModal();
  }

  const deleteHoliday = async response => {
    if (response) {
      let request = await api.holidays.delete(selectedHoliday.id);
      if (request.ok) {
        getData();
        setSuccess("Feriado excluído com sucesso");
        setShowAlertSuccess(true);
      } else {
        setError(request);
        setShowAlertError(true);
      }
      handleCloseDialog();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  }

  const handleEdit = item => {
    setSelectedHoliday(item);
    setShowModal(true);
  };

  const handleDelete = item => {
    setSelectedHoliday(item);
    setShowDialog(true);
  };

  return (
    <>
      <SuccessRequest message={success} showAlert={showAlertSuccess} setShowAlert={() => setShowAlertSuccess(false)} />
      <FailedRequest message={error.description} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Feriados">
          <div className="w-100 d-flex justify-content-between">
            <Button type="button" variant="primary" size="sm" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
              Novo
            </Button>
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <HolidayRegistrationModal
            showModal={showModal}
            handleClose={handleCloseModal}
            handleSubmit={create} />
          <DialogModal
            message="Tem certeza que deseja excluir este feriado?"
            showModal={showDialog}
            handleClose={handleCloseDialog} response={deleteHoliday} />

          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }} />
          </AdvanceTableProvider>
          <Pagination
            data={holidays}
            filters={filters}
            setFilters={setFilters} />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>

  );
};

export default HolidayRegistration;