import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "helpers/utils";
import { useEffect, useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AssetInfo = ({ data }) => {
  const [showBody, setShowBody] = useState(true);
  const [asset, setAsset] = useState(null);

  useEffect(() => {
    setAsset(data.asset ? data.asset : null);
  }, [data]);

  return (
    <Card>
      <div
        className="w-100 bg-100 d-flex card-header py-2 align-items-center justify-content-between"
        style={{ cursor: 'pointer' }}
        onClick={() => setShowBody(!showBody)}
      >
        <h6 className="m-0">Informações do Ativo</h6>
        <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
      </div>
      {showBody && (
        <Card.Body>
          {asset ? (
            <Row className="align-items-start">
              <Col xs={12}>
                <p className="fw-bold text-800 fs-10 mb-1">Nome</p>
                <p><Link to={`/assets/${asset.id}`} target="_blank" className="fs-10">{asset.name || "---"} ({asset.assetType?.name || "---"})</Link></p>
              </Col>
              <Col xs={12}>
                <p className="fw-bold text-800 fs-10 mb-1">Número de Série</p>
                <p className="fs-10">{asset.serialNumber || "---"}</p>
              </Col>
              <Col xs={12}>
                <p className="fw-bold text-800 fs-10 mb-1">Descrição</p>
                <p className="fs-10">{asset.description || "---"}</p>
              </Col>
              <Col xs={12}>
                <p className="fw-bold text-800 fs-10 mb-1">Data de Criação</p>
                <p className="fs-10">{formatDateTime(asset.creationDate)}</p>
              </Col>
              <Col xs={12}>
                <p className="fw-bold text-800 fs-10 mb-1">Cliente</p>
                <p className="fs-10">{asset.customer?.fantasyName || "Sem cliente associado"}</p>
              </Col>
              <Col xs={12}>
                <p className="fw-bold text-800 fs-10 mb-1">Status do Ativo</p>
                <Badge
                  bg={asset.inactive ? "secondary" : "success"}
                  className="fs-10"
                >
                  {asset.inactive ? "Inativo" : "Ativo"}
                </Badge>
              </Col>
            </Row>
          ) : (
            <span>Nenhum ativo associado.</span>
          )}
        </Card.Body>
      )}
    </Card>
  );
};

export default AssetInfo;