import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import BusinessHourEditModal from "components/modals/BusinessHourEditModal";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { formatTime } from "helpers/utils";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";

const BusinessHourRegistration = () => {
  const api = useApi();
  const [showModal, setShowModal] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [businessHours, setBusinessHours] = useState([]);

  const getData = async () => {
    const response = await api.businessHours.list();
    if (response) setBusinessHours(response);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      accessorKey: 'day',
      header: 'Dia da semana',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { day } = rowData.row.original;
        return (
          <span>{day.description}</span>
        );
      }
    },
    {
      accessorKey: 'startBusiness',
      header: 'Início do expediente',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { startBusiness } = rowData.row.original;
        return (
          <span>{formatTime(startBusiness)}</span>
        );
      }
    },
    {
      accessorKey: 'endBusiness',
      header: 'Fim do expediente',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { endBusiness } = rowData.row.original;
        return (
          <span>{formatTime(endBusiness)}</span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <button className="btn btn-link btn-sm" onClick={() => handleEdit(item)}>
            <FontAwesomeIcon icon={faPen} size="sm" />
          </button>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: businessHours,
    columns
  });

  const edit = async bodyChange => {
    const response = await api.businessHours.edit(selectedItem.id, bodyChange);
    if (response.status) {
      setError(response);
      setShowAlertError(true);
    } else {
      getData();
      setSuccess("Horário editado com sucesso");
      setShowAlertSuccess(true);
    }
    handleCloseModal();
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleEdit = item => {
    setSelectedItem(item);
    setShowModal(true);
  };

  return (
    <>
      <SuccessRequest message={success} showAlert={showAlertSuccess} setShowAlert={() => setShowAlertSuccess(false)} />
      <FailedRequest message={error.description} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Horário comercial" subTitle="Configure o horário comercial de sua empresa." />
        <FalconComponentCard.Body>
          <BusinessHourEditModal
            showModal={showModal}
            handleClose={handleCloseModal}
            handleSubmit={edit}
            data={selectedItem} />

          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }} />
          </AdvanceTableProvider>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>

  );
};

export default BusinessHourRegistration;