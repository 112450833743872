import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import TypeTicketEditModal from "components/modals/TypeTicketEditModal";
import TypeTicketRegistrationModal from "components/modals/TypeTicketRegistrationModal";
import Pagination from "components/pagination";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
const TypeTicketRegistration = () => {
  const api = useApi();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [typeTickets, setTypeTickets] = useState({
    content: [
      { name: '', agents: [], inactive: false }
    ]
  });
  const [selectedTypeTicket, setSelectedTypeTicket] = useState({});
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    includeInactives: false
  });

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.typeTickets.list(queryParams);
    if (response.content) setTypeTickets(response);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'inactive',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { inactive } = rowData.row.original;
        return (
          inactive
            ? <span className="text-danger">Inativo</span>
            : <span className="text-success">Ativo</span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <button className="btn btn-link btn-sm" onClick={() => handleEdit(item)}>
            <FontAwesomeIcon icon={faPen} size="sm" />
          </button>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: typeTickets.content,
    columns
  });

  const handleCloseModalRegister = () => {
    setShowModal(false);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  }

  const handleEdit = item => {
    setSelectedTypeTicket(item);
    setShowModalEdit(true);
  };

  const createTypeTicket = async body => {
    const response = await api.typeTickets.create(body);
    if (response.status) {
      setError(response);
      setShowAlertError(true);
    } else {
      getData();
      setSuccess("Tipo cadastrado com sucesso");
      setShowAlert(true);
    }
    handleCloseModalRegister();
  };

  const editTypeTicket = async bodyChange => {
    const response = await api.typeTickets.edit(selectedTypeTicket.id, bodyChange);
    if (response.status) {
      setError(response);
      setShowAlertError(true);
    } else {
      getData();
      setSuccess("Tipo editado com sucesso");
      setShowAlert(true);
    }
    handleCloseModalEdit();
  }

  return (
    <>
      <SuccessRequest message={success} showAlert={showAlert} setShowAlert={() => setShowAlert(false)} />
      <FailedRequest message={error.description} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Tipos de ticket">
          <div className="w-100 d-flex justify-content-between">
            <Button type="button" variant="primary" size="sm" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
              Novo
            </Button>
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <TypeTicketEditModal
            showModal={showModalEdit}
            data={selectedTypeTicket}
            handleClose={handleCloseModalEdit}
            handleSubmit={editTypeTicket} />
          <TypeTicketRegistrationModal
            showModal={showModal}
            handleClose={handleCloseModalRegister}
            handleSubmit={createTypeTicket} />

          <Row className="justify-content-end g-0">
            <Col className="col-auto px-3">
              <Form.Select size="sm" className="rounded-pill mb-3" onChange={e => setFilters(prevFilters => ({ ...prevFilters, includeInactives: e.target.value }))}>
                <option value="true">Filtro por status</option>
                <option value="true">Todos</option>
                <option value="false">Somente ativos</option>
              </Form.Select>
            </Col>
          </Row>

          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }} />
          </AdvanceTableProvider>
          <Pagination
            data={typeTickets}
            filters={filters}
            setFilters={setFilters} />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>

  );
};

export default TypeTicketRegistration;