import { faEdit, faInfoCircle, faPowerOff, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import Loading from "components/loading/Loading";
import TurnOffDattoIntegrationModal from "components/modals/TurnOffDattoIntegrationModal";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const ApiCredentials = ({ created, isNew, getSettings }) => {
  const api = useApi();
  const form = useRef(null);
  const [validated, setValidated] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [success, setSuccess] = useState('');
  const [turnOn, setTurnOn] = useState(false);
  const [error, setError] = useState('');
  const [body, setBody] = useState({
    id: 0,
    apiUrl: '',
    apiKey: '',
    apiSecret: '',
    inactive: false
  });
  const toCreate = body.id == 0;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await api.apiCredentials.dattoRMM.find();

    if (response.status == 404) {
      clearFields();
      isNew(true);
      setTurnOn(false);
    } else {
      setBody(response);
      setIsEdit(false);
      isNew(false);
      setTurnOn(true);
    }
  };

  const create = async (e) => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      const response = await api.apiCredentials.dattoRMM.create(body);

      if (response.id) {
        setBody(response);
        setSuccess("Credenciais criada com sucesso");
        setShowSuccess(true);
        created(true);
        setValidated(false);
        fetchData();
        getSettings();
      } else {
        setError(response.userMessage);
        setShowError(true);
      }
      setLoading(false);
    }
  };

  const edit = async (e) => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      const response = await api.apiCredentials.dattoRMM.edit(body);

      if (response.id) {
        setBody(response);
        setSuccess("Credenciais alterada com sucesso");
        setShowSuccess(true);
        setValidated(false);
        setIsEdit(false);
      } else {
        setError(response.userMessage);
        setShowError(true);
      }
      setLoading(false);
    }
  };

  const deleteCredentials = async response => {
    const request = await api.apiCredentials.dattoRMM.delete(response);
    if (request.ok) {
      fetchData();
      setSuccess("Credenciais excluídas com sucesso");
      setShowSuccess(true);
    } else {
      setError(request.userMessage);
      setShowError(true);
    }
    setShowDialog(false);
    setTurnOn(false);
  };

  const handleChangeBody = e => {
    setBody({ ...body, [e.target.name]: e.target.value });
  };

  const clearFields = () => {
    setBody({
      id: 0,
      apiUrl: '',
      apiKey: '',
      apiSecret: '',
      inactive: false
    });
    setIsEdit(true);
  };

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <TurnOffDattoIntegrationModal
        show={showDialog}
        close={() => setShowDialog(false)}
        response={deleteCredentials} />
      {loading && <Loading />}

      <Form ref={form} validated={validated}>
        <Flex alignItems="center" className="w-75">
          <p className="fw-bold m-0 pb-4 w-50">
            Integração API Datto RMM
            <OverlayTrigger placement="right-end" overlay={<Tooltip>Para obter as credenciais da API do Datto RMM, acesse as configurações globais, vá até a seção de usuários, selecione o usuário desejado e gere as chaves de API.</Tooltip>}>
              <span><FontAwesomeIcon icon={faInfoCircle} className="ms-1" size="sm" /></span>
            </OverlayTrigger>
          </p>

          <Flex justifyContent="between" className="w-100">
            <Flex alignItems="center" className="mb-3">
              <Button
                className="me-2"
                onClick={() => turnOn ? setShowDialog(true) : setTurnOn(true)}>
                <FontAwesomeIcon icon={faPowerOff} className="me-2" />
                {!turnOn ? 'Habilitar integração' : 'Desabilitar integração'}
              </Button>

              <Link target="_blank" to="https://www.mspdesk.com.br/documentation/integration/datto-rmm">Aprenda como integrar</Link>
            </Flex>

            {!isEdit && body.id !== 0 && (

              <Button
                className="mb-3"
                onClick={() => setIsEdit(true)}
              >
                <FontAwesomeIcon icon={faEdit} className="me-1" />
                Editar
              </Button>

            )}
          </Flex>
        </Flex>

        {turnOn &&
          <>
            <Form.Group as={Flex} className="mb-3" alignItems="center">
              <Form.Label className="fw-semibold w-25">Servidor (url)</Form.Label>
              <div className="w-50">
                <Form.Control
                  type="text"
                  className="w-100"
                  value={body.apiUrl}
                  name="apiUrl"
                  required
                  disabled={!isEdit}
                  onChange={handleChangeBody} />
                <Form.Control.Feedback>OK!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">URL é obrigatória.</Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group as={Flex} className="mb-3" alignItems="center">
              <Form.Label className="fw-semibold w-25">Nome de usuário (chave)</Form.Label>
              <div className="w-50">
                <Form.Control
                  type="text"
                  className="w-100"
                  value={body.apiKey}
                  name="apiKey"
                  required
                  onChange={handleChangeBody}
                  autocomplete="off"
                  disabled={!isEdit}
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false" />
                <Form.Control.Feedback>OK!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Nome de usuário é obrigatório.</Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group as={Flex} className="mb-3" alignItems="center">
              <Form.Label className="fw-semibold w-25">Senha (segredo)</Form.Label>
              <div className="w-50">
                <Form.Control
                  type="password"
                  className="w-100"
                  required={toCreate}
                  disabled={!isEdit}
                  value={body.apiSecret}
                  name="apiSecret"
                  onChange={handleChangeBody}
                />
                <Form.Control.Feedback>OK!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Senha é obrigatória.</Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group as={Flex} className="mb-3" alignItems="center">
              <Form.Label className="fw-semibold w-25">Ativo</Form.Label>
              <Form.Check
                type="switch"
                className="w-50"
                value={body.inactive}
                disabled={!isEdit}
                name="inactive"
                checked={!body.inactive}
                onChange={() => setBody({ ...body, inactive: !body.inactive })} />
            </Form.Group>

            <Form.Group as={Flex} justifyContent="end" className="w-75" alignItems="center">
              <Button
                type="submit"
                variant="primary"
                onClick={e => toCreate ? create(e) : edit(e)}
                disabled={loading || !isEdit}>
                <FontAwesomeIcon icon={faSave} className="me-2" />
                {body.id == 0 ? 'Salvar e verificar conexão' : 'Salvar'}
              </Button>
            </Form.Group>
          </>
        }
      </Form>
    </>
  );
};

export default ApiCredentials;
