import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import ServiceGroupEditModal from "components/modals/ServiceGroupEditModal";
import ServiceGroupRegistrationModal from "components/modals/ServiceGroupRegistrationModal";
import Pagination from "components/pagination";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const ServiceGroupRegistration = () => {
  const api = useApi();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [selectedServiceGroup, setSelectedServiceGroup] = useState({});
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [serviceGroups, setServiceGroups] = useState({
    content: [
      { name: '', agents: [], inactive: false }
    ]
  });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    includeInactives: false
  });

  const getData = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.serviceGroups.list(queryParams);
    if (response.content) setServiceGroups(response);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'agents',
      header: 'Agentes',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { agents } = rowData.row.original;
        return (
          agents.map((agent, index) => (
            <span key={index}>
              {agent.firstName}
              {index !== agents.length - 1 && ', '}
            </span>
          ))
        )
      }
    },
    {
      accessorKey: 'inactive',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { inactive } = rowData.row.original;
        return (
          inactive
            ? <span className="text-danger">Inativo</span>
            : <span className="text-success">Ativo</span>


        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <button className="btn btn-link btn-sm" onClick={() => handleEdit(item)}>
            <FontAwesomeIcon icon={faPen} size="sm" />
          </button>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: serviceGroups.content,
    columns
  });

  const handleCloseModalRegister = () => {
    setShowModal(false);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  }

  const handleEdit = item => {
    setSelectedServiceGroup(item);
    setShowModalEdit(true);
  };

  const createServiceGroup = async body => {
    const response = await api.serviceGroups.create(body);
    if (response.status) {
      setError(response);
      setShowAlertError(true);
    } else {
      getData();
      setSuccess("Grupo de serviço criado com sucesso");
      setShowAlert(true);
    }
    handleCloseModalRegister();
  };

  const editServiceGroup = async bodyChange => {
    let response = await api.serviceGroups.edit(selectedServiceGroup.id, bodyChange);
    if (response) {
      getData();
      setSuccess("Grupo de serviço editado com sucesso");
      setShowAlert(true);
    } else {
      setError(response);
      setShowAlertError(true);
    }
    handleCloseModalEdit();
  }

  return (
    <>
      <SuccessRequest message={success} showAlert={showAlert} setShowAlert={() => setShowAlert(false)} />
      <FailedRequest message={error.description} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Grupos de serviço">
          <div className="w-100 d-flex justify-content-between">
            <Button type="button" variant="primary" size="sm" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
              Novo
            </Button>
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <ServiceGroupEditModal
            showModal={showModalEdit}
            data={selectedServiceGroup}
            refreshData={() => getData()}
            handleClose={handleCloseModalEdit}
            handleSubmit={editServiceGroup}
            error={error => setError(error)} />

          <ServiceGroupRegistrationModal
            showModal={showModal}
            handleClose={handleCloseModalRegister}
            handleSubmit={createServiceGroup} />
          <Row className="justify-content-end g-0">
            <Col className="col-auto px-3">
              <Form.Select size="sm" className="mb-3 rounded-pill" onChange={e => setFilters(prevFilters => ({ ...prevFilters, includeInactives: e.target.value }))}>
                <option value="">Filtro por status</option>
                <option value="true">Todos</option>
                <option value="false">Somente ativos</option>
              </Form.Select>
            </Col>
          </Row>
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }} />
          </AdvanceTableProvider>
          <Pagination
            data={serviceGroups}
            filters={filters}
            setFilters={setFilters} />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>

  );
};

export default ServiceGroupRegistration;