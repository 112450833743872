import { faExclamationTriangle, faLaptop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import LayoutIntegration from "../LayoutIntegration";
import ApiCredentials from "./ApiCredentials";
import DattoAlerts from "./DattoAlerts";
import DattoDevices from "./DattoDevices";
import DattoSites from "./DattoSites";
import DattoTicketSettings from "./DattoTicketSettings";

const DattoRMMIntegration = () => {
  const api = useApi();

  const [wasCreated, setWasCreated] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const response = await api.apiCredentials.dattoRMM.settings.find();

    if (response.id) {
      setSettings(response);
    }
  };

  return (
    <LayoutIntegration>
      <Row>
        <Col xxl={12}>
          <Tab.Container defaultActiveKey="apiCredentials">
            <SimpleBar>
              <header className="p-0">
                <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="apiCredentials"
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FontAwesomeIcon
                        icon="key"
                        className="text-600 tab-icon"
                      />
                      <h6 className="text-600 mb-0 ms-1">Credenciais API</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="sites"
                      disabled={isNew}
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FontAwesomeIcon icon="user" className="text-600" />
                      <h6 className="text-600 mb-0 ms-1">Sites</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="devices"
                      disabled={isNew}
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FontAwesomeIcon
                        icon={faLaptop} e
                        className="text-600"
                      />
                      <h6 className="text-600 mb-0 ms-1">Dispositivos</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="alerts"
                      disabled={isNew}
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} className="text-600" />
                      <h6 className="text-600 mb-0 ms-1">Alertas</h6>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tickets"
                      disabled={isNew}
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FontAwesomeIcon icon="ticket-alt" className="text-600" />
                      <h6 className="text-600 mb-0 ms-1">Definições de Ticket</h6>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </header>
            </SimpleBar>
            <Tab.Content className="p-3">
              <Tab.Pane eventKey="apiCredentials">
                <ApiCredentials
                  isNew={response => setIsNew(response)}
                  created={response => setWasCreated(response)}
                  getSettings={() => getSettings()} />
              </Tab.Pane>
              <Tab.Pane eventKey="sites">
                <DattoSites created={wasCreated} />
              </Tab.Pane>
              <Tab.Pane eventKey="devices">
                <DattoDevices settings={settings} setSettings={setSettings} />
              </Tab.Pane>
              <Tab.Pane eventKey="alerts">
                <DattoAlerts settings={settings} setSettings={setSettings} />
              </Tab.Pane>
              <Tab.Pane eventKey="tickets">
                <DattoTicketSettings settings={settings} setSettings={setSettings} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </LayoutIntegration>
  );
};

export default DattoRMMIntegration;