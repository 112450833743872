import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const NewReleaseModal = ({ show, close }) => {
  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>🎉 Novidades da Nova Release! 🎉</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Estamos entusiasmados em apresentar as mais recentes atualizações e
          funcionalidades para melhorar ainda mais sua experiência no MSP Desk!
          Confira abaixo as novidades desta versão:
        </p>

        <h5>Novas Funcionalidades:</h5>

        <p>
          <strong>Importação de Catálogos de Serviço via CSV:</strong> Agora você pode
          importar catálogos de serviço diretamente a partir de um arquivo CSV, facilitando
          a gestão dos serviços oferecidos. Para utilizar essa função, vá para
          <strong> Configurações &gt; Catálogo de Serviço</strong>.
        </p>

        <p>
          <strong>Sincronização de Dispositivos Datto RMM com Ativos:</strong> Com essa
          nova integração, é possível sincronizar os dispositivos do Datto RMM diretamente
          com os ativos do MSP Desk. Isso permite um controle centralizado e mais eficiente
          dos dispositivos. A configuração está disponível em
          <strong> Configurações &gt; Integrações</strong>.
        </p>

        <p>
          <strong>Introdução ao Módulo de Gestão de Ativos:</strong> Agora você pode gerenciar
          os ativos de TI diretamente no MSP Desk. Esse novo módulo foi projetado para ajudar
          na organização e acompanhamento de seus ativos, oferecendo uma visão mais completa.
        </p>

        <p>
          <strong>Gerenciamento de Tipos de Ativos:</strong> Adicionamos a possibilidade de criar,
          editar e excluir diferentes tipos de ativos, permitindo que você personalize a maneira
          como organiza seus recursos. Acesse essa opção em
          <strong> Configurações &gt; Tipos de Ativos</strong>.
        </p>

        <p>
          <strong>Propriedades Personalizadas para Tipos de Ativos:</strong> Agora é possível
          adicionar e configurar propriedades personalizadas para cada tipo de ativo, criando
          campos específicos conforme suas necessidades. Para isso, vá para
          <strong> Configurações &gt; Tipos de Ativos &gt; Selecione um Tipo de Ativo</strong>.
        </p>

        <h5>🚀 Novidade Exclusiva desta Versão:</h5>

        <p>
          <strong>Sincronização de Alertas do Datto RMM com Tickets:</strong> Agora ficou ainda
          mais fácil gerenciar incidentes e alertas com as novas funcionalidades integradas ao
          Datto RMM!
        </p>

        <p>
          <strong>Veja o que há de novo:</strong>
        </p>

        <ul>
          <li>
            <strong>Listagem de Alertas do Datto RMM:</strong> Consulte diretamente no MSP Desk
            todos os alertas do Datto RMM, organizados e prontos para análise, sem precisar sair
            da plataforma.
          </li>
          <li>
            <strong>Criação de Tickets com Base em Alertas:</strong> Agora, os alertas do Datto
            RMM podem ser configurados para gerar tickets automaticamente no MSP Desk, de acordo
            com suas preferências. Isso permite uma automação eficiente e garante que nenhum
            incidente passe despercebido.
          </li>
        </ul>

        <p>
          Para aprender como configurar e usar essas funcionalidades, acesse a {' '}
          <Link
            to="https://www.mspdesk.com.br/documentation/integration/datto-rmm"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentação da integração aqui
          </Link>.
        </p>

        <p>
          Aproveite essas novas funcionalidades e fique atento para mais melhorias que estão por vir!
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default NewReleaseModal;
