import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "components/pagination";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import List from "list.js";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DialogModal from "./DialogModal";

const BlacklistModal = ({ show, handleClose }) => {
  const api = useApi();
  const [filters, setFilters] = useState({
    page: 0,
    size: 10
  });
  const queryParams = new URLSearchParams(filters).toString();
  const [blacklist, setBlacklist] = useState({ content: [] });
  const [itemId, setItemId] = useState('');
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [error, setError] = useState({});
  const options = {
    valueNames: ['mailbox', 'sender', 'subject', 'description', 'creationDate'],
    item: '<tr><td className="mailbox"></td><td className="sender"></td><td className="subject"></td><td className="description"></td><td className="creationDate"></td></tr>'
  };
  const blackList = new List('blacklist', options);

  useEffect(() => {
    if (show) getData();
  }, [filters, show]);

  const getData = async () => {
    let response = await api.blacklist.list(queryParams);
    setBlacklist(response);
  };

  const deleteItem = async () => {
    let request = await api.blacklist.delete(itemId);
    if (request.ok) {
      getData();
      setShowAlert(true);
    } else {
      setError(request);
      setShowAlertError(true);
    }
    setShowDialogConfirm(false);
  };

  const handleDelete = id => {
    setItemId(id);
    setShowDialogConfirm(true);
  };

  return (
    <>
      <SuccessRequest
        message="E-mail desbloqueado com sucesso!"
        showAlert={showAlert}
        setShowAlert={() => setShowAlert(false)} />
      <FailedRequest
        message={error.description}
        showAlert={showAlertError}
        setShowAlert={() => setShowAlertError(false)} />
      <DialogModal
        message="Tem certeza que deseja desbloquear este remetente?"
        showModal={showDialogConfirm}
        handleClose={() => setShowDialogConfirm(false)}
        response={deleteItem} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Emails bloqueados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="blacklist">
            <div className="table-responsive scrollbar">
              <table className="table table-bordered table-striped fs-10 mb-0">
                <thead className="bg-200 text-900">
                  <tr>
                    <th className="sort" data-sort="email">E-mail</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {blacklist.content.map((item, i) => (
                    <tr key={i}>
                      <td className="email">{item.sender}</td>
                      <td className="d-flex justify-content-center">
                        <Button variant="link" size="sm" onClick={() => handleDelete(item.id)}>
                          <FontAwesomeIcon icon={faTrash} size="sm" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Pagination
              data={blacklist}
              filters={filters}
              setFilters={setFilters} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BlacklistModal;