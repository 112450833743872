import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ContactInfo = ({ data }) => {
  const api = useApi();
  const [tickets, setTickets] = useState({ content: [] });
  const [customerId, setCustomerId] = useState('');
  const [filters, setFilters] = useState({ size: 5, customer: '' });
  const [viewLastTickets, setViewLastTickets] = useState(false);
  const [showBody, setShowBody] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [contacts, setContacts] = useState([]);
  const [body, setBody] = useState({
    contactCustomer: { id: '' }
  });
  const [contact, setContact] = useState({});

  const isDisabledForm = data.status && data.status.status == "COMPLETED" || data.status && data.status.status == "CLOSED";

  useEffect(() => {
    setFilters(prevFilters => ({ ...prevFilters, customer: data.customer ? data.customer.id : '' }));
    setCustomerId(data.customer ? data.customer.id : '');
    setBody(data.contactCustomer && { contactCustomer: { id: parseInt(data.contactCustomer.id) } });
    setContact(data.contactCustomer);
  }, [data]);

  useEffect(() => {
    getTickets();
    getContacts();
  }, [customerId]);

  const getContacts = async () => {
    const filter = {
      page: 0,
      size: 100
    };
    const queryParams = new URLSearchParams(filter).toString();
    if (customerId) {
      let response = await api.customersContacts.list(
        customerId,
        queryParams
      );
      setContacts(response.content);
    }
  };

  const getTickets = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    let response = await api.tickets.list(queryParams);
    setTickets(response);
  };

  const editContact = async e => {
    setBody({ contactCustomer: { id: parseInt(e.target.value) } });
    const response = await api.tickets.edit(data.id, { contactCustomer: { id: parseInt(e.target.value) } });

    if (response.id) {
      setContact(response.contactCustomer);
      setIsSuccess(true);
    } else {
      setError(response.userMessage);
      setIsError(true);
    }
  };

  return (
    <>
      <SuccessRequest
        message={"Contato alterado com sucesso"}
        showAlert={isSuccess}
        setShowAlert={() => setIsSuccess(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={isError}
        setShowAlert={(() => setIsError(false))} />
      <Card>
        <div className="w-100 d-flex bg-100 card-header py-2 align-items-center justify-content-between" style={{ cursor: 'pointer' }} onClick={() => setShowBody(!showBody)}>
          <h6 className="m-0">{data.customer ? data.customer.fantasyName || data.customer.name : ''}</h6>
          <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
        </div>

        {showBody &&
          <Card.Body>
            <Row className="g-0 pb-x1 align-items-sm-center align-items-xl-start">
              <Col xs={12} sm="auto" xl={12}>
                <Form.Select
                  value={body?.contactCustomer?.id}
                  onChange={editContact}
                  size='sm'
                  disabled={isDisabledForm}>
                  {contacts.map(item => (
                    <option value={item.id} key={item.name}>{item.name}</option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Row className="g-0 justify-content-lg-between">
              <Row>
                <Col xs="auto" md={6} lg="auto" className="ps-md-5 ps-xl-0">
                  {contact && contact.email &&
                    <>
                      <h6 className="m-0">E-mail</h6>
                      <a href={`mailto:${contact ? contact.email : ''}`} className="fs-10 mb-1">
                        {contact && contact.email ? contact.email : '---'}
                      </a>
                    </>
                  }

                  {contact && contact.landline &&
                    <>
                      <h6 className="mt-2 mb-0">Telefone</h6>
                      <a href={`tel:'${contact && contact.landline ? contact.landline : '---'}`} className="fs-10 mb-1">
                        {contact && contact.landline ? contact.landline : '---'}
                      </a>
                    </>
                  }

                  {contact && contact.phone &&
                    <>
                      <h6 className="mt-2 mb-0">Celular</h6>
                      <a href={`tel:'${contact && contact ? contact.phone : ''}`} className="fs-10">
                        {contact && contact.phone ? contact.phone : '---'}
                      </a>
                    </>
                  }
                  <div className='w-100 mt-3'>
                    <h6 role='button' className="mt-2 mb-0 fs-10"
                      onClick={() => setViewLastTickets(!viewLastTickets)}>
                      {viewLastTickets ? 'Esconder' : 'Ver'} Últimos Tickets {'>'}
                    </h6>
                  </div>
                  {viewLastTickets && <div className='mt-3'>
                    {tickets.content && tickets.content.map((item, i) => (
                      <Link to={`/tickets/${item.id}`} key={i} className='d-block w-100 fs-10 mb-2' style={{ overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        #{item.code} {item.subject}
                      </Link>
                    ))}
                  </div>}
                </Col>
              </Row>
            </Row>
          </Card.Body>
        }
      </Card>
    </>
  );
};

export default ContactInfo;