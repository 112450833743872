import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeSelect from 'components/common/TreeSelect';
import Editor from 'components/editor/Editor';
import Loading from 'components/loading/Loading';
import ContactRegistrationModal from 'components/modals/ContactRegistrationModal';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import { priorities } from 'helpers/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Dropdown, Form, Offcanvas, Row } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import CustomerRegistrationOffcanvas from './CustomerRegistrationOffcanvas';

const TicketRegistrationOffcanvas = ({
  show,
  handleClose,
  preTicketData,
  preTicketId,
  refreshPretickets
}) => {
  const api = useApi();
  const navigate = useNavigate();
  const form = useRef(null);
  const inputCustomer = useRef(null);
  const inputContact = useRef(null);
  const divOffcanvas = useRef(null);

  const [isCreateAndComplete, setIsCreateAndComplete] = useState(false);
  const [showMenuCustomer, setShowMenuCustomers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalContact, setShowModalContact] = useState(false);
  const [showModalCustomer, setShowModalCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [validated, setValidated] = useState(false);
  const isPreTicketIdEmpty = preTicketId == '';

  const [error, setError] = useState({});
  const [filters, setFilters] = useState({
    size: 100,
    includeInactives: false,
    name: ''
  });
  const queryParams = new URLSearchParams(filters).toString();
  const [customers, setCustomers] = useState({ content: [] });
  const [contacts, setContacts] = useState({ content: [] });
  const [typesTicket, setTypesTicket] = useState({ content: [] });
  const [agents, setAgents] = useState([]);
  const [serviceGroups, setServiceGroups] = useState({ content: [] });
  const [serviceCatalogs, setServiceCatalogs] = useState([]);

  const [newContact, setNewContact] = useState({});
  const [createdCustomer, setCreatedCustomer] = useState({});
  const [agent, setAgent] = useState({ id: '' });
  const [serviceGroup, setServiceGroup] = useState({ id: '' });
  const [typeTicket, setTypeTicket] = useState({ id: '' });

  const [body, setBody] = useState({
    customer: { id: '' },
    subject: '',
    description: '',
    priority: '',
    contactCustomer: { id: '' },
    typeService: 'INTERNAL',
    timeSpent: '00:00',
    realized: '',
    isAppointment: false,
    typeViewNote: 'PRIVATE'
  });

  useEffect(() => {
    if (show) {
      getTypesTicket();
      getServiceGroups();
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      setContacts({ content: [] });
      setAgents([]);
      setServiceCatalogs([]);
      clearFields();
      setIsCreateAndComplete(false);
      setValidated(false);

      setTimeout(() => {
        if (inputCustomer.current) inputCustomer.current.focus();
      }, 100);
    }
  }, [show]);

  useEffect(() => {
    setBody(preBody => ({
      ...preBody,
      subject: preTicketData && preTicketData.subject,
      description: preTicketData && preTicketData.description,
      customer: {
        id:
          preTicketData && preTicketData.customer
            ? preTicketData.customer.id
            : ''
      },
      contactCustomer: {
        id:
          preTicketData && preTicketData.contact ? preTicketData.contact.id : ''
      }
    }));
    setFilters(prevFilters => ({
      ...prevFilters,
      name:
        preTicketData && preTicketData.customer && preTicketData.customer.name
    }));
    if (preTicketData.contact && preTicketData.contact.id == 0) {
      setNewContact(preTicketData.contact);
      const newContent = [...contacts.content];
      newContent.unshift(preTicketData.contact);
      setContacts({ content: newContent });
    }
  }, [preTicketData]);

  useEffect(() => {
    setFilters(prevFilters => ({ ...prevFilters, name: createdCustomer.name }));
    handleSetBody(true, 'customer', createdCustomer.id);
    setSelectedCustomer(createdCustomer);
  }, [createdCustomer]);

  useEffect(() => {
    getCustomers();
  }, [filters.name]);

  useEffect(() => {
    getContacts();
  }, [body.customer.id]);

  useEffect(() => {
    getAgents();
    getServiceCatalogs();
  }, [serviceGroup.id]);

  const getCustomers = async () => {
    if (filters.name != '') {
      const params = new URLSearchParams(filters).toString();
      let response = await api.customers.list(params);
      if (response.content) setCustomers(response);
    }
  };
  const getContacts = async () => {
    if (body.customer.id) {
      let response = await api.customersContacts.list(
        body.customer.id,
        queryParams
      );
      if (contacts.content.map(item => item.email != newContact.email)) {
        const newContent = [...response.content];
        if (Object.keys(newContact).length != 0) newContent.unshift(newContact);
        setContacts({ content: newContent });
      } else {
        setContacts({ content: [...response.content] });
      }
    }
  };
  const getTypesTicket = async () => {
    let response = await api.typeTickets.list(queryParams);
    if (response.content) setTypesTicket(response);
  };
  const getServiceGroups = async () => {
    let response = await api.serviceGroups.list(queryParams);
    if (response.content) setServiceGroups(response);
  };
  const getAgents = async () => {
    if (serviceGroup.id) {
      let response = await api.serviceGroupsAgents.list(serviceGroup.id);
      setAgents(response);
    }
  };
  const getServiceCatalogs = async () => {
    if (serviceGroup.id) {
      let response = await api.serviceCatalogs.listInTreeViewByServiceGroup(
        serviceGroup.id
      );
      setServiceCatalogs(response);
    }
  };

  const handleSetBody = (hasId, fieldName, value) => {
    hasId
      ? setBody(prevBody => ({
        ...prevBody,
        [fieldName]: { id: parseInt(value) }
      }))
      : setBody(prevBody => ({ ...prevBody, [fieldName]: value }));
  };

  const onSubmit = async e => {
    setLoading(true);
    if (
      form.current.checkValidity() === false ||
      body.description.trim() === '' ||
      body.description === '<p><br></p>'
    ) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response;
      if (isPreTicketIdEmpty) {
        response = await api.tickets.create(body);
      } else {
        response = await api.tickets.createFromPreticket(preTicketId, body);
        refreshPretickets();
      }

      if (response.id) {
        setShowSuccess(true);
        setValidated(false);
        setIsCreateAndComplete(false);
        clearFields();
        handleClose();
      } else {
        setError(response);
        setShowError(true);
      }
    }
    setLoading(false);
  };

  const createAndOpen = async e => {
    setLoading(true);
    if (
      form.current.checkValidity() === false ||
      body.description.trim() === '' ||
      body.description === '<p><br></p>'
    ) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response;
      if (isPreTicketIdEmpty) response = await api.tickets.create(body);
      else response = await api.tickets.createFromPreticket(preTicketId, body);

      if (response.id) {
        setShowSuccess(true);
        setValidated(false);
        setIsCreateAndComplete(false);
        clearFields();
        handleClose();
        navigate(`/tickets/${response.id}`);
      } else {
        setError(response);
        setShowError(true);
      }
    }
    setLoading(false);
  };

  const createAndComplete = async e => {
    setLoading(true);
    if (
      form.current.checkValidity() === false ||
      body.description.trim() === '' ||
      body.description === '<p><br></p>'
    ) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response;
      if (isPreTicketIdEmpty)
        response = await api.tickets.createAndComplete(body);
      else
        response = await api.tickets.createFromPreticketAndComplete(
          preTicketId,
          body
        );

      if (response.id) {
        setShowSuccess(true);
        setValidated(false);
        setIsCreateAndComplete(false);
        clearFields();
        handleClose();
        navigate(`/tickets/${response.id}`);
      } else {
        setError(response);
        setShowError(true);
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setFilters(prevFilters => ({ ...prevFilters, name: '' }));
    setBody({
      customer: { id: '' },
      subject: '',
      description: '',
      priority: '',
      contactCustomer: { id: '' },
      typeService: 'INTERNAL',
      timeSpent: '00:00',
      realized: ''
    });
    setServiceGroup({ id: '' });
    setAgent({ id: '' });
    setTypeTicket({ id: '' });
  };

  const handleSelectServiceCatalog = ids => {
    handleSetBody(true, 'serviceCatalog', ids[0]);
    handleSetBody(true, 'category', ids[1]);
    handleSetBody(true, 'subcategory', ids[2]);
  }

  return (
    <>
      {loading && <Loading />}
      <SuccessRequest
        message="Ticket criado com sucesso"
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
      />
      <FailedRequest
        message={error.description}
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
      />
      <ContactRegistrationModal
        showModal={showModalContact}
        handleClose={() => setShowModalContact(false)}
        customer={selectedCustomer}
        newContact={response => handleSetBody(true, 'contactCustomer', response.id)}
        isSuccess={response => {
          if (response) {
            getContacts();
          }
        }}
      />
      <CustomerRegistrationOffcanvas
        showModal={showModalCustomer}
        handleClose={() => setShowModalCustomer(false)}
        createdCustomer={item => setCreatedCustomer(item)}
        isSuccess={response => { }}
      />
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ minWidth: '55%', maxWidth: '90%' }}
        ref={divOffcanvas}
      >
        <Offcanvas.Header
          tabIndex={-1}
          className="d-flex align-items-center space-between"
        >
          <Offcanvas.Title className="m-0">Novo ticket</Offcanvas.Title>
          <span role="button" onClick={() => handleClose()} tabIndex={-1}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </Offcanvas.Header>
        <Offcanvas.Body className="pb-0">
          <Form ref={form} validated={validated}>
            <Row>
              <Form.Group as={Col} md={6} controlId="exampleForm.customer">
                <Form.Label className="mb-1 fs-10">
                  Cliente <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control

                  type="text"
                  placeholder="Digite para pesquisar"
                  value={filters.name}
                  ref={inputCustomer}
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  onFocus={() => setShowMenuCustomers(true)}
                  onChange={e =>
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      name: e.target.value
                    }))
                  }
                />
                {showMenuCustomer && (
                  <ul
                    className="position-sticky px-0 list-customer bg-100 shadow rounded z-3"
                    style={{ height: 'auto', maxHeight: 350, overflowY: 'auto' }}
                  >
                    {customers.content.map(item => (
                      <li
                        style={{ listStyleType: 'none', cursor: 'pointer' }}
                        className="px-3 py-2 bg-primary-hover item-list-hover"
                        data-value={item.id}
                        key={item.id + item.type}
                        onClick={() => {
                          setFilters(prevFilters => ({
                            ...prevFilters,
                            name: item.fantasyName || item.name
                          }));
                          handleSetBody(true, 'customer', item.id);
                          setSelectedCustomer(item);
                          inputContact.current.focus();
                          setShowMenuCustomers(false);
                        }}
                      >
                        {item.fantasyName || item.name}
                      </li>
                    ))}
                  </ul>
                )}
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Selecione um cliente.
                </Form.Control.Feedback>

                <div className="w-100 d-flex justify-content-end">
                  <Button
                    variant="link"

                    onClick={() => setShowModalCustomer(true)}
                  >
                    Adicionar novo cliente
                  </Button>
                </div>
              </Form.Group>

              <Form.Group as={Col} md={6}>
                <Form.Label className="mb-1 fs-10">
                  Contato <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  ref={inputContact}
                  autoComplete={false}

                  value={body.contactCustomer.id}
                  onChange={e => handleSetBody(true, 'contactCustomer', e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {contacts.content.map(item => (
                    <option value={item.id} key={item.id + item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Selecione um contato.
                </Form.Control.Feedback>

                <div className="w-100 d-flex justify-content-end mt-1">
                  <Button
                    variant="link"

                    onClick={() => setShowModalContact(true)}
                    disabled={body.customer.id === ''}
                  >
                    Adicionar novo contato
                  </Button>
                </div>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md={4} className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label className="mb-1 mt-2 fs-10">
                  Prioridade <span className="text-danger"> *</span>
                </Form.Label>
                <Form.Select
                  required

                  value={body.priority}
                  onChange={e => handleSetBody(false, 'priority', e.target.value)}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {priorities.map((item, i) => (
                    <option value={item.value} key={i}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Selecione uma prioridade.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md={4} className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label className="mb-1 mt-2 fs-10">
                  Grupo de serviço
                  {isCreateAndComplete && <span className="text-danger"> *</span>}
                </Form.Label>
                <Form.Select

                  value={serviceGroup.id}
                  required={isCreateAndComplete}
                  onChange={e => {
                    setServiceGroup({ id: e.target.value });
                    handleSetBody(true, 'serviceGroup', e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {serviceGroups.content.map((item, i) => (
                    <option value={item.id} key={item.name + i}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Selecione um grupo de serivço.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md={4} className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label className="mb-1 mt-2 fs-10">
                  Agente
                  {isCreateAndComplete && <span className="text-danger"> *</span>}
                </Form.Label>
                <Form.Select

                  value={agent.id}
                  required={isCreateAndComplete}
                  onChange={e => {
                    setAgent({ id: e.target.value });
                    handleSetBody(true, 'agent', e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {agents.map(item => (
                    <option value={item.id} key={item.phone + item.firstName}>
                      {item.firstName}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Selecione um agente.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md={6} className="mb-2">
                <Form.Label className="mb-1 fs-10">
                  Catálogo de serviço
                  {isCreateAndComplete && <span className="text-danger"> *</span>}
                </Form.Label>
                <TreeSelect data={serviceCatalogs} onSelect={handleSelectServiceCatalog} />
              </Form.Group>

              <Form.Group as={Col} md={6} className="mb-2" controlId="exampleForm.ControlInput1">
                <Form.Label className="mb-1 fs-10">
                  Tipo
                  {isCreateAndComplete && <span className="text-danger"> *</span>}
                </Form.Label>
                <Form.Select

                  value={typeTicket.id}
                  required={isCreateAndComplete}
                  onChange={e => {
                    setTypeTicket({ id: e.target.value });
                    handleSetBody(true, 'typeTicket', e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Selecione uma opção
                  </option>
                  {typesTicket.content.map(item => (
                    <option value={item.id} key={item.id + item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Selecione um tipo.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label className="mb-1 fs-10">
                Assunto <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control

                type="text"
                placeholder="Assunto"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                value={body.subject}
                required
                onChange={e => handleSetBody(false, 'subject', e.target.value)}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Insira um assunto
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label className="mb-1 fs-10">
                Descrição <span className="text-danger"> *</span>
              </Form.Label>
              <Editor
                data={body.description}
                value={value => handleSetBody(false, 'description', value)}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Insira uma descrição.
              </Form.Control.Feedback>
            </Form.Group>

            <div
              className={isCreateAndComplete ? 'd-block' : 'visually-hidden'}
            >
              <Row className="mb-3 d-flex">
                <Form.Group as={Col} md="3" className="me-3">
                  <Form.Label>Apontar horas</Form.Label>
                  <Form.Switch
                    value={body.isAppointment}
                    onChange={() =>
                      handleSetBody(false, 'isAppointment', !body.isAppointment)
                    }
                  />
                </Form.Group>
                {!body.isAppointment ? (
                  <>
                    <Form.Group as={Col} md="8">
                      <Form.Label>Visualização</Form.Label>
                      <Form.Select
                        aria-label="Tipo"
                        className="flex-1 me-2"
                        value={body.typeViewNote}
                        onChange={e =>
                          handleSetBody(false, 'typeViewNote', e.target.value)
                        }
                        required={isCreateAndComplete}
                      >
                        <option value="PRIVATE" key="PRIVATE">
                          Privado
                        </option>
                        <option value="PUBLIC" key="PUBLIC">
                          Público
                        </option>
                      </Form.Select>
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Escolha uma opção!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group as={Col} md="4" controlId="appointment.type">
                      <Form.Label>
                        Tipo de horas
                        {isCreateAndComplete && (
                          <span className="text-danger">*</span>
                        )}
                      </Form.Label>
                      <Form.Select
                        aria-label="Tipo"
                        className="rounded-pill flex-1 me-2"
                        value={body.typeService}
                        onChange={e =>
                          handleSetBody(false, 'typeService', e.target.value)
                        }
                        required={body.isAppointment}
                      >
                        <option value="INTERNAL" key="INTERNAL">
                          Interno
                        </option>
                        <option value="EXTERNAL" key="EXTERNAL">
                          Externo
                        </option>
                      </Form.Select>
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Escolha uma opção!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="appointment.profile">
                      <Form.Label>
                        Horas
                        {isCreateAndComplete && (
                          <span className="text-danger">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        type="time"

                        value={body.timeSpent}
                        onChange={e =>
                          handleSetBody(false, 'timeSpent', e.target.value)
                        }
                        required={body.isAppointment}
                      />
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Insira as horas trabalhadas!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}
              </Row>
              <Form.Group className="mb-3" controlId="appointment.profile">
                <Form.Label>
                  Realizado
                  {isCreateAndComplete && (
                    <span className="text-danger"> *</span>
                  )}
                </Form.Label>
                <Editor
                  value={value => handleSetBody(false, 'realized', value)}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Insira uma descrição!
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Form>

          <footer className="bg-100 p-2 d-flex justify-content-end" style={{ width: '55%', position: 'fixed', right: 0, bottom: 0 }}>
            <Button
              variant="falcon-default"
              onClick={handleClose}
              className="me-2"
            >
              Cancelar
            </Button>
            {!isCreateAndComplete ? (
              <div className="position-relative d-flex">
                <Button
                  style={{ borderRadius: '40px 0 0 40px' }}
                  variant="primary"
                  type="submit"
                  onClick={onSubmit}
                >
                  Salvar
                </Button>
                <Dropdown drop="up" className="d-flex h-100">
                  <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

                  <Dropdown.Menu
                    className="position-absolute w-100"
                    style={{ transform: 'translateX(-65%)' }}
                  >
                    <Dropdown.Item onClick={createAndOpen}>
                      Salvar e visualizar
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setIsCreateAndComplete(true)}>
                      Salvar e concluir
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={createAndComplete}
              >
                Salvar
              </Button>
            )}
          </footer>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <Button
    style={{ borderRadius: '0 5px 5px 0' }}
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon={faChevronDown} />
  </Button>
));

export default TicketRegistrationOffcanvas;
