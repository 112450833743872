import { faCopy as regularCopy } from "@fortawesome/free-regular-svg-icons";
import { faCog, faSave, faCopy as solidCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import WebhookConfigModal from "components/modals/WebhookConfigModal";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { priorities } from "helpers/utils";
import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";

const DattoAlerts = ({ settings, setSettings }) => {
  const api = useApi();

  const [showWebhookModal, setShowWebhookModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [copied, setCopied] = useState(false);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [dattoPriorites, setDattoPriorities] = useState([]);
  const [prioritiesMapping, setPrioritiesMapping] = useState([]);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [generatedPassword, setGeneratedPassword] = useState('');

  useEffect(() => {
    getDattoPriorities();
    setCopied(false);
    setGeneratedPassword('');
  }, []);

  useEffect(() => {
    const mappings = dattoPriorites.map(priority => ({
      dattoPriorityTitle: priority.description,
      dattoPriority: priority.value,
      mspPriority: priority.mspPriority || "",
      idMapping: priority.idMapping || null,
    }));
    setPrioritiesMapping(mappings);
  }, [dattoPriorites]);

  const editSettings = async () => {
    const response = await api.apiCredentials.dattoRMM.settings.edit(settings);
    handleRequest(response);
  };

  const getDattoPriorities = async () => {
    const response = await api.dattoPriorities.list();
    if (Array.isArray(response)) {
      setDattoPriorities(response);
    }
  };

  const saveMappings = async () => {
    deleteMappings();

    // Filtra apenas os mapeamentos válidos (com mspPriority definido) para envio ao backend
    const validMappings = prioritiesMapping.filter(mapping => mapping.mspPriority !== "");
    const request = await api.dattoPriorityMapping.batchMap(validMappings);

    handleMappingRequest(request);
  };

  const handleSelectChange = (dattoPriority, mspPriority, idMapping) => {
    // Atualiza ou remove o ID para exclusão dependendo da escolha
    if (mspPriority === "") {
      setIdsToDelete(prevIds => [...prevIds, idMapping]); // Adiciona idMapping à lista de exclusão
    } else {
      setIdsToDelete(idsToDelete.filter(id => id !== idMapping)); // Remove de idsToDelete se não for "Não mapeado"
    }

    // Atualiza a prioridade mapeada
    const updatedMappings = prioritiesMapping.map(mapping =>
      mapping.dattoPriority === dattoPriority
        ? { ...mapping, mspPriority }
        : mapping
    );
    setPrioritiesMapping(updatedMappings);
  };

  const deleteMappings = async () => {
    if (idsToDelete.length > 0) {
      const deleteResponse = await api.dattoPriorityMapping.batchUnmap(idsToDelete);
      if (!deleteResponse.ok) {
        setError("Erro ao excluir mapeamentos");
        setShowError(true);
        return;
      }
    }
  };

  const generatePassword = async () => {
    setCopied(false);
    const response = await api.apiCredentials.dattoRMM.settings.generatePassword();

    if (typeof response === "string") {
      setGeneratedPassword(response);
    } else {
      setError(response.userMessage);
      setShowError(true);
    }
  };

  const handleRequest = (request) => {
    if (request.id) {
      setSuccess("Configurações alteradas com sucesso");
      setShowSuccess(true);
      setSettings(request);
    } else {
      setError(request.userMessage);
      setShowError(true);
    }
  };

  const handleMappingRequest = (request) => {
    if (request.ok) {
      setSuccess("Alterações de mapeamento salvas com sucesso");
      setShowSuccess(true);
      setIdsToDelete([]); // Limpa o estado de exclusão após sucesso
    } else {
      setError(request.userMessage || "Erro ao salvar mapeamentos");
      setShowError(true);
    }
  };

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <WebhookConfigModal
        show={showWebhookModal}
        close={() => setShowWebhookModal(false)} />

      <Form>
        <p className="fs-9 fw-bold mb-3">Mapeamento de Alertas</p>
        <Form.Group as={Flex} alignItems="center" justifyContent="between" className="mb-2">
          <div className="d-flex">
            <Form.Check
              type="switch"
              value={settings?.syncAlerts}
              onChange={() => setSettings({ ...settings, syncAlerts: !settings.syncAlerts })}
              checked={settings.syncAlerts}
            />
            <Form.Label className="m-0">Sincronizar Alertas</Form.Label>
          </div>

          <Button size="sm" onClick={editSettings}>
            <FontAwesomeIcon icon={faSave} className="me-1" size="sm" />
            Salvar
          </Button>
        </Form.Group>
      </Form>

      <hr />

      <div className="mb-4">
        <p>Para habilitar essa funcionalidade, os webhooks devem ser configurados no Datto RMM alternando o envio de um webhook na seção 'Resposta' de um Monitor de dispositivo. Use a configuração de webhook abaixo para configurar as respostas Alerta Recebido e Alerta Resolvido.</p>
        <Button size="sm" className="me-2" onClick={() => setShowWebhookModal(true)}>
          <FontAwesomeIcon icon={faCog} size="sm" className="me-1" />
          Configuração de Webhook
        </Button>
      </div>

      <div>
        <p className="m-0">Gere uma senha e adicione-a ao atributo <code>state</code> do payload.</p>
        <p className="fs-10 m-0 mb-2">A senha gerada será mostrada apenas uma vez. Gerar uma nova senha impedirá que a antiga funcione.</p>
        <div className="w-50">
          {generatedPassword
            ? <>
              <div>
                <strong className="flex-1">{generatedPassword}</strong>
                <CopyToClipboard text={generatedPassword} onCopy={() => setCopied(true)}>
                  <Button variant="link" size="sm">
                    <FontAwesomeIcon icon={copied ? solidCopy : regularCopy} />
                  </Button>
                </CopyToClipboard>
              </div>
            </>
            : <strong>**************************</strong>}

          <Button size="sm" className="mt-2" onClick={() => generatePassword()}>Gerar</Button>

        </div>
      </div>

      <hr />

      <p className="fs-9 fw-bold mt-3 mb-3">Mapeamento de Prioridades</p>

      <Table responsive>
        <thead>
          <tr>
            <th className="text-900 fs-10">Prioridade Datto RMM</th>
            <th className="w-75 text-900 fs-10">Prioridade MSP Desk</th>
          </tr>
        </thead>
        <tbody>
          {prioritiesMapping.map((mapping, index) => (
            <tr key={index}>
              <td className="fs-10">{mapping.dattoPriorityTitle}</td>
              <td>
                <Form.Select
                  value={mapping.mspPriority}
                  onChange={(e) => handleSelectChange(mapping.dattoPriority, e.target.value, mapping.idMapping)}>
                  <option value="" className="text-danger">Não mapeado</option>
                  {priorities.map(item => (
                    <option key={item.value} value={item.value}>{item.name}</option>
                  ))}
                </Form.Select>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Flex className="w-100" justifyContent="end">
        <Button onClick={saveMappings} size="sm">
          <FontAwesomeIcon icon={faSave} className="me-1" />
          Salvar
        </Button>
      </Flex>
    </>
  );
};

export default DattoAlerts;