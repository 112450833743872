import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";

const CustomerRegistrationOffcanvas = ({ showModal, handleClose, isSuccess, createdCustomer }) => {
  const api = useApi();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [error, setError] = useState({});
  const [body, setBody] = useState({
    type: 'JURIDICAL_PERSON',
    corporateName: '',
    fantasyName: '',
    cnpj: '',
    stateRegistration: '',
    name: '',
    cpf: '',
    gender: '',
    birthday: ''
  });
  const form = useRef(null);
  const isCompany = body.type === "JURIDICAL_PERSON";

  useEffect(() => {
    if (showModal) {
      clearFields();
      setValidated(false);
    }
  }, [showModal]);

  const create = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      isSuccess(false);
    } else {
      const newBody = {
        type: body.type,
        corporateName: isCompany ? body.corporateName : null,
        fantasyName: body.fantasyName,
        cnpj: isCompany ? body.cnpj : null,
        stateRegistration: body.stateRegistration,
        name: isCompany ? body.fantasyName : body.name,
        cpf: !isCompany ? body.cpf : null,
        gender: body.gender == '' || isCompany ? null : body.gender,
        birthday: body.birthday === '' || isCompany ? null : body.birthday,
      };

      const response = await api.customers.create(newBody);
      if (response.status) {
        setError(response);
        setShowAlertError(true);
        setValidated(true);
      } else {
        setShowAlert(true);
        isSuccess(true);
        createdCustomer(response);
        setValidated(false);
        clearFields();
        handleClose();
      }
    }
  };

  const createAndOpen = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      const newBody = {
        type: body.type,
        corporateName: isCompany ? body.corporateName : null,
        fantasyName: body.fantasyName,
        cnpj: isCompany ? body.cnpj : null,
        stateRegistration: isCompany ? body.stateRegistration : null,
        name: isCompany ? body.fantasyName : body.name,
        cpf: !isCompany ? body.cpf : null,
        gender: body.gender == '' || isCompany ? null : body.gender,
        birthday: body.birthday === '' || isCompany ? null : body.birthday,
      };

      const response = await api.customers.create(newBody);
      if (response.status) {
        setError(response);
        setShowAlertError(true);
        isSuccess(false);
        setValidated(true);
      } else {
        clearFields();
        handleClose();
        createdCustomer(response);
        isSuccess(true);
        navigate(`/registration/customers/${response.id}`);
        setShowAlert(true);
        setValidated(false);
      }
    }
  };

  function clearFields() {
    setBody({
      type: 'JURIDICAL_PERSON',
      corporateName: '',
      fantasyName: '',
      cnpj: '',
      stateRegistration: '',
      name: '',
      cpf: '',
      gender: '',
      birthday: ''
    });
  };

  return (
    <>
      <SuccessRequest message="Cliente cadastrado com sucesso" showAlert={showAlert} setShowAlert={() => setShowAlert(false)} />
      <FailedRequest message={error.userMessage} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <Offcanvas show={showModal} onHide={handleClose} placement="end" style={{ minWidth: '45%', maxWidth: '90%' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Novo cliente</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form validated={validated} ref={form}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="agent.firstName">
                <Form.Label>Tipo <span className="text-danger">*</span></Form.Label>
                <Form.Select aria-label="Tipo" className="flex-1 me-2" value={body.type} onChange={e => setBody(prevBody => ({ ...prevBody, type: e.target.value }))} required>
                  <option value="JURIDICAL_PERSON">Jurídica</option>
                  <option value="NATURAL_PERSON">Física</option>
                </Form.Select>
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira um tipo.</Form.Control.Feedback>
              </Form.Group>

              {isCompany ?
                <Form.Group as={Col} md="8" controlId="agent.email">
                  <Form.Label>CNPJ <span className="text-danger">*</span></Form.Label>
                  <MaskedInput
                    mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                    guide={false}
                    required={isCompany}
                    type="text"
                    placeholder="CNPJ"
                    value={body.cnpj}
                    min={18}
                    minLength={18}
                    onChange={e => setBody(prevBody => ({ ...prevBody, cnpj: e.target.value }))}
                    render={(ref, props) => <Form.Control ref={ref} {...props} />}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um CNPJ.</Form.Control.Feedback>
                </Form.Group> :
                <Form.Group as={Col} md="8" controlId="agent.password">
                  <Form.Label>CPF <span className="text-danger">*</span></Form.Label>
                  <MaskedInput
                    mask={[/[1-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                    guide={false}
                    required={!isCompany}
                    type="text"
                    placeholder="CPF"
                    value={body.cpf}
                    min={14}
                    minLength={14}
                    onChange={e => setBody(prevBody => ({ ...prevBody, cpf: e.target.value }))}
                    render={(ref, props) => <Form.Control ref={ref} {...props} />}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um cpf.</Form.Control.Feedback>
                </Form.Group>}
            </Row>

            <Row className="mb-3">
              {isCompany ?
                <Form.Group as={Col} md="6" controlId="agent.lastName">
                  <Form.Label>Razão social <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    required={isCompany}
                    type="text"
                    placeholder="Razão social"
                    value={body.corporateName}
                    onChange={e => setBody(prevBody => ({ ...prevBody, corporateName: e.target.value }))}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira uma razão social.</Form.Control.Feedback>
                </Form.Group>
                :
                <Form.Group as={Col} md="6" controlId="agent.email">
                  <Form.Label>Nome completo <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    required={!isCompany}
                    type="text"
                    placeholder="Nome completo"
                    value={body.name}
                    onChange={e => setBody(prevBody => ({ ...prevBody, name: e.target.value }))}
                    onBlur={() => setBody(prevBody => ({ ...prevBody, fantasyName: body.name }))}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um nome completo.</Form.Control.Feedback>
                </Form.Group>
              }

              <Form.Group as={Col} md="6" controlId="agent.lastName">
                <Form.Label>{isCompany ? "Nome fantasia" : "Nome de tratamento"} <span className="text-danger">*</span></Form.Label>
                <Form.Control
                  required={isCompany}
                  type="text"
                  placeholder="Nome fantasia"
                  value={body.fantasyName}
                  onChange={e => setBody(prevBody => ({ ...prevBody, fantasyName: e.target.value }))}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira um nome fantasia.</Form.Control.Feedback>
              </Form.Group>
            </Row>

            {isCompany &&
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="agent.email">
                  <Form.Label>Inscrição estadual</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Inscrição estadual"
                    value={body.stateRegistration}
                    onChange={e => setBody(prevBody => ({ ...prevBody, stateRegistration: e.target.value }))}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um inscrição estadual.</Form.Control.Feedback>
                </Form.Group>
              </Row>
            }

            {!isCompany &&
              <>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Data de nascimento</Form.Label>
                    <Form.Control
                      type="date"
                      value={body.birthday}
                      onChange={e => setBody(prevBody => ({ ...prevBody, birthday: e.target.value }))}
                      max={`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`} />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira a data de nascimento.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Gênero</Form.Label>
                    <Form.Select
                      aria-label="Gênero"
                      className="flex-1 me-2"
                      value={body.gender}
                      onChange={e => setBody(prevBody => ({ ...prevBody, gender: e.target.value }))}>
                      <option value="">Selecione uma opção</option>
                      <option value="MALE">Masculino</option>
                      <option value="FEMININE">Feminino</option>
                    </Form.Select>
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira o gênero.</Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </>
            }
          </Form>
        </Offcanvas.Body>
        <footer className="fixed-bottom p-2 w-100 d-flex justify-content-end">
          <Button variant="primary" onClick={createAndOpen} className="me-2">
            Salvar e editar
          </Button>
          <Button variant="primary" type="submit" onClick={create}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </footer>
      </Offcanvas>
    </>
  );
};

export default CustomerRegistrationOffcanvas;