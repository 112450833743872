import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from "helpers/api";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import MaskedInput from "react-text-mask";

const ContactCustomerEditModal = ({ data, showModal, handleClose, handleSubmit, error, refreshData }) => {
  const api = useApi();
  const [body, setBody] = useState({
    id: '',
    name: '',
    email: '',
    phone: '',
    landline: '',
    inactive: false,
    customer: { id: '' }
  });
  const [validated, setValidated] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [associatedCustomers, setAssociatedCustomers] = useState([]);
  const [showMenuCustomer, setShowMenuCustomer] = useState(false);
  const [showMenuMainCustomer, setShowMenuMainCustomer] = useState(false);
  const [filters, setFilters] = useState({ size: 100, includeInactives: false, name: '' });
  const [nameMainCustomer, setNameMainCustomer] = useState('');
  const [nameCustomer, setNameCustomer] = useState('');
  const form = useRef(null);

  useEffect(() => {
    if (Object.keys(data).length != 0) {
      setNameMainCustomer(data.customer && data.customer.fantasyName || data.customer.name);
      setBody(data);
      setValidated(false);
      getAssociatedCustomers();
    }
  }, [data]);

  useEffect(() => {
    if (showModal) {
      setBody(data);
      setValidated(false);
    }
  }, [showModal]);

  useEffect(() => {
    getCustomers();
  }, [filters.name]);

  useEffect(() => {
    setFilters(prevFilters => ({ ...prevFilters, name: nameMainCustomer }));
  }, [nameMainCustomer]);

  useEffect(() => {
    setFilters(prevFilters => ({ ...prevFilters, name: nameCustomer }));
  }, [nameCustomer]);

  const getCustomers = async () => {
    if (filters.name != '') {
      const queryParams = new URLSearchParams(filters).toString();
      let response = await api.customers.list(queryParams);
      setCustomers(response.content);
    }
  };

  const getAssociatedCustomers = async () => {
    let response = await api.customers.listByContact(data.id);
    setAssociatedCustomers(response);
  };

  const associateCustomer = async e => {
    e.preventDefault();
    let response;
    if (selectedCustomer != null) response = await api.customersContacts.associate(selectedCustomer, body.id);
    if (response.ok) {
      getAssociatedCustomers();
      refreshData();
    }
    else
      error(response);
  };

  const unassociateCustomer = async (e, id) => {
    e.preventDefault();
    let response = await api.customersContacts.unassociate(id, body.id);
    if (response.ok) {
      getAssociatedCustomers();
      refreshData();
    }
    else
      error(response);
  };


  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      handleSubmit({
        name: body.name,
        email: body.email,
        phone: body.phone,
        landline: body.landline,
        inactive: body.inactive,
        customer: { id: body.customer.id }
      });
      setBody({
        name: '',
        email: '',
        phone: '',
        landline: '',
        inactive: false,
        customer: { id: '' }
      });
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar contato</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nome"
              autoFocus
              value={body.name}
              onChange={e => setBody(prevState => ({ ...prevState, name: e.target.value }))}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>E-mail {body.phone === '' && <span className="text-danger">*</span>}</Form.Label>
            <Form.Control
              required={body.phone === ''}
              type="email"
              placeholder="E-mail"
              value={body.email}
              onChange={e => setBody(prevState => ({ ...prevState, email: e.target.value }))}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um e-mail.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="agent.phone">
            <Form.Label>Telefone {body.email === '' && <span className="text-danger">*</span>}</Form.Label>
            <MaskedInput
              mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              placeholder="(00) 0000-0000"
              required={body.email === '' && body.phone === ''}
              guide={false}
              value={body.landline}
              onChange={e => setBody(prevBody => ({ ...prevBody, landline: e.target.value }))}
              render={(ref, props) => (
                <Form.Control ref={ref} {...props} />
              )}
            />

            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um número de telefone.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="agent.phone">
            <Form.Label>Celular {body.email === '' && <span className="text-danger">*</span>}</Form.Label>
            <MaskedInput
              mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              placeholder="(00) 00000-0000"
              required={body.email === '' && body.landline === ''}
              guide={false}
              value={body.phone}
              onChange={e => setBody(prevBody => ({ ...prevBody, phone: e.target.value }))}
              render={(ref, props) => (
                <Form.Control ref={ref} {...props} />
              )}
            />

            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um número de celular.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Ativo</Form.Label>
            <Form.Check
              type="switch"
              value={body.inactive}
              checked={!body.inactive}
              onChange={e => setBody({ ...body, inactive: !body.inactive })} />
          </Form.Group>

          <Form.Label>Associe um cliente</Form.Label>
          <Form.Group className="w-100">
            <Row className="d-flex align-items-center">
              <Col md={9}>
                <Form.Control
                  type='search'
                  placeholder='Digite para pesquisar'
                  value={nameCustomer}
                  onFocus={() => setShowMenuCustomer(true)}
                  o
                  onChange={e => setNameCustomer(e.target.value)} />
              </Col>
              <Col md={3} className="p-0">
                <Button variant="primary" size="sm" onClick={associateCustomer}>
                  + Adicionar
                </Button>
              </Col>
            </Row>

            {showMenuCustomer &&
              <ul className='px-0 list-customer bg-white shadow rounded z-3' style={{ height: 'auto', maxHeight: 350, overflowY: 'auto' }}>
                {customers.map(item => (
                  <li
                    style={{ listStyleType: 'none', cursor: 'pointer' }}
                    className='px-3 py-2 bg-primary-hover item-list-hover'
                    data-value={item.id}
                    key={item.id + item.type} onClick={() => {
                      setNameCustomer(item.fantasyName || item.name);
                      setSelectedCustomer(item.id);
                      setShowMenuCustomer(false);
                    }}>
                    {item.fantasyName || item.name}
                  </li>
                ))}
              </ul>
            }
          </Form.Group>
        </Form>
        <div className="table-responsive scrollbar">
          <table className="table fs-10 mb-0 mt-3">
            <thead className="bg-200 text-900">
              <tr>
                <th>Clientes associados</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {associatedCustomers.map((item, i) => (
                <tr key={i}>
                  <td data-value={item.id}>
                    <span className="d-flex">
                      <Form.Check
                        type="radio"
                        value={body.customer.id}
                        checked={body.customer.id == item.id}
                        className="me-1"
                        onChange={() => setBody(prevFilters => ({ ...prevFilters, customer: { id: item.id } }))} />
                      {item.fantasyName || item.name}
                    </span>
                  </td>
                  <td>
                    <button className="btn btn-link text-danger btn-sm" onClick={e => unassociateCustomer(e, item.id)}>
                      x
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactCustomerEditModal;