import userAvatar from 'assets/img/user-avatar.png';
import Avatar from "components/common/Avatar";
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import Loading from 'components/loading/Loading';
import DialogModal from 'components/modals/DialogModal';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ChangePassword from '../settings/ChangePassword';

const ProfileOthers = () => {
  const api = useApi();
  const [userId, setUserId] = useState('');
  const [userPhoto, setUserPhoto] = useState({ url: '' });
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFile = useRef(null);
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [error, setError] = useState({ description: '' });
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getUserIdAuthenticated();
  }, []);

  useEffect(() => {
    if (userId)
      getUserPhoto();
  }, [userId]);

  useEffect(() => {
    updatePhoto();
  }, [selectedFile]);

  const getUserIdAuthenticated = async () => {
    let response = await api.agents.getUserIdAuthenticated();
    if (response)
      setUserId(response);
  };


  const getUserPhoto = async () => {
    let request = await api.agentPhoto.find(userId);
    if (request.ok) {
      const response = await request.json();
      setUserPhoto(response);
    }
  };

  const updatePhoto = async () => {
    setLoading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      let response = await api.agentPhoto.update(userId, formData);
      if (response.status) {
        setError(response);
        setShowAlertError(true);
      } else {
        getUserPhoto();
        setShowAlert(true);
        setSuccess("Foto de perfil alterada com sucesso");
        setSelectedFile(null);
      }
    }
    setLoading(false);
  };

  const deletePhoto = async response => {
    setLoading(true);
    if (response) {
      let request = await api.agentPhoto.delete(userId);
      if (request.ok) {
        setShowDialogConfirm(false);
        setSuccess("Foto excluída com sucesso");
        setShowAlert(true);
        getUserPhoto();
      } else {
        setError(request);
        setShowAlertError(true);
      }
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <DialogModal
        message="Tem certeza que deseja excluir a foto de perfil?"
        showModal={showDialogConfirm}
        handleClose={() => setShowDialogConfirm(false)}
        response={deletePhoto} />
      <SuccessRequest
        message={success}
        showAlert={showAlert}
        setShowAlert={() => setShowAlert(false)} />
      <FailedRequest
        message={error.description}
        showAlert={showAlertError}
        setShowAlert={() => setShowAlertError(false)} />
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Flex className='w-100' justifyContent="start">
                <IconButton
                  onClick={() => navigate(-1)}
                  variant="falcon-default"
                  size="sm"
                  icon="arrow-left"
                  className="me-3"
                />
                <Card.Title>Meu perfil</Card.Title>
              </Flex>
            </Card.Header>
            <Card.Body>
              <div className='w-100 d-flex flex-column align-items-center'>
                <Avatar
                  size="5xl"
                  src={userPhoto.url || userAvatar}
                  mediaClass="img-thumbnail shadow-sm"
                />

                <div className='mt-3'>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="camera"
                    transform="shrink-2"
                    iconAlign="middle"
                    className="me-2"
                    onClick={() => inputFile.current.click()}
                  >
                    <span className="d-none d-md-inline-block ms-1">Alterar foto</span>
                  </IconButton>
                  <input
                    type="file"
                    ref={inputFile}
                    onChange={e => setSelectedFile(e.target.files[0])}
                    style={{ display: 'none' }} />

                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="trash"
                    transform="shrink-2"
                    iconAlign="middle"
                    onClick={() => setShowDialogConfirm(true)}
                  >
                    <span className="d-none d-md-inline-block ms-1">Remover foto</span>
                  </IconButton>
                </div>
              </div>
              <br />
              <ChangePassword
                success={success => {
                  if (success != null) {
                    setSuccess(success);
                    setShowAlert(true);
                  }
                }}
                error={error => {
                  if (error != null) {
                    setError(error);
                    setError(true);
                  }
                }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProfileOthers;