
import { faCopy as regularCopy } from "@fortawesome/free-regular-svg-icons";
import { faCopy as solidCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import './webhookstyle.css';

const WebhookConfigModal = ({ show, close }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (show) {
      setCopied(false);
    }
  }, [show]);

  const payloadContent = `{
  "site_uid": "[site_uid]",
  "site_name": "[site_name]",
  "device_uid": "[device_uid]",
  "device_name": "[device_hostname]",
  "description": "[alert_message_pt]",
  "alert_uid": "[alert_uid]",
  "alert_priority": "[alert_priority]",
  "alert_category": "[alert_category]",
  "alert_type": "[alert_type]",
  "alert_resolved": false,
  "state": "Substitua pela senha gerada"
}`;

  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Configuração de Webhook</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          URL: <code>https://api.mspdesk.com.br/v1/webhooks/alert-datto-rmm</code>
        </p>
        <p>
          Content Type: <code>application/JSON</code>
        </p>
        <div className="mb-3">
          <h5>Payload</h5>
          <div className="code-block">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <span>JSON</span>
              <div className="d-flex align-items-center">
                {copied && <p className="text-success fs-10 m-0">Texto copiado com sucesso</p>}
                <CopyToClipboard text={payloadContent} onCopy={() => setCopied(true)}>
                  <Button variant="link" size="sm">
                    <FontAwesomeIcon icon={copied ? solidCopy : regularCopy} />
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
            <pre className="code-content">{payloadContent}</pre>
          </div>
        </div>
        <p>
          <strong>Nota:</strong> Os payloads de alertas resolvidos devem ter a propriedade <code>alert_resolved</code> definida como <code>true</code>.
        </p>
        <p> Para usar uma tradução em Francês, Italiano, Alemão, Inglês ou Português, substitua o campo <code>alert_message</code> por uma das seguintes opções: <code>alert_message_fr</code>, <code>alert_message_it</code>, <code>alert_message_de</code>, <code>alert_message_en</code> ou <code>alert_message_pt</code>. </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={close}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WebhookConfigModal;
