import App from 'App';
import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import paths, { rootPaths } from './paths';

import ErrorLayout from '../layouts/ErrorLayout';
import MainLayout from '../layouts/MainLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import AlertList from 'components/alerts/AlertList';
import CustomerDetails from 'components/app/support-desk/contact-details/CustomerDetails';
import Settings from 'components/app/support-desk/quick-links/Settings';
import TicketsPreview from 'components/app/support-desk/tickets-preview/TicketsPreview';
import AssetEdit from 'components/assets/AssetEdit';
import AssetList from 'components/assets/AssetList';
import Authorized from 'components/authentication/Authorized';
import Login from 'components/authentication/Login';
import ProtectedRoute from 'components/authentication/ProtectedRoute';
import SupportDesk from 'components/dashboards/support-desk';
import Error403 from 'components/errors/Error403';
import DattoRMMIntegration from 'components/integration/datto-rmm/DattoRMMIntegration';
import MSPTalksIntegration from 'components/integration/msptalks/MSPTalksIntegration';
import Starter from 'components/pages/Starter';
import MyCompany from 'components/pages/company/MyCompany';
import Landing from 'components/pages/landing/Landing';
import ProfileUser from 'components/pages/user/profile/ProfileUser';
import AgentRegistration from 'components/registration/AgentRegistration';
import AssetTypeEdit from 'components/registration/AssetTypeEdit';
import AssetTypeRegistration from 'components/registration/AssetTypeRegistration';
import BusinessHourRegistration from 'components/registration/BusinessHourRegistration';
import ContactRegistration from 'components/registration/ContactRegistration';
import CustomerRegistration from 'components/registration/CustomerRegistration';
import EmailBoxRegistration from 'components/registration/EmailBoxRegistration';
import HolidayRegistration from 'components/registration/HolidayRegistration';
import PauseReasonRegistration from 'components/registration/PauseReasonRegistration';
import ProfileRegistration from 'components/registration/ProfileRegistration';
import ScheduledTicketEdit from 'components/registration/ScheduledTicketEdit';
import ScheduledTicketList from 'components/registration/ScheduledTicketList';
import ScheduledTicketRegistration from 'components/registration/ScheduledTicketRegistration';
import ServiceCatalogRegistration from 'components/registration/ServiceCatalogRegistration';
import ServiceGroupRegistration from 'components/registration/ServiceGroupRegistration';
import SlaPolicyEdit from 'components/registration/SlaPolicyEdit';
import TypeTicketRegistration from 'components/registration/TypeTicketRegistration';
import ServiceCatalogDetails from 'components/registration/service-catalog-details/ServiceCatalogDetails';
import SlaPolicyList from 'components/registration/sla-policy-list/SlaPolicyList';
import ReportExecutiveCustomer from 'components/reports/executive/ReportExecutiveCustomer';
import ReportSupportDeskOverView from 'components/reports/overview/ReportSupportDeskOverView';
import ReportProductivity from 'components/reports/productivity/ReportProductivity';
import ListPreTickets from 'components/tickets/ListPreTickets';
import ListTickets from 'components/tickets/ListTickets';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';

const routes = [
  {
    element: <App />,
    children: [
      {
        path: paths.starter,
        element: <Starter />
      },
      {
        path: paths.landing,
        element: <Landing />
      },
      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error403,
            element: <Error403 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          }
        ]
      },
      {
        element: <AuthSimpleLayout />,
        children: [
          {
            path: paths.login,
            element: <Login />
          },
          {
            path: paths.authorized,
            element: <Authorized />
          }
        ]
      },
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            path: paths.listPreTickets,
            element: <ProtectedRoute><ListPreTickets /></ProtectedRoute>
          },
          {
            path: paths.settings,
            element: <ProtectedRoute><Settings /></ProtectedRoute>
          },
          {
            path: rootPaths.dashboardRoot,
            children: [
              {
                path: paths.dashTickets,
                element: <ProtectedRoute><SupportDesk /></ProtectedRoute>
              }
            ]
          },
          {
            path: rootPaths.ticketsRoot,
            children: [
              {
                path: paths.listTickets,
                element: <ProtectedRoute><ListTickets /></ProtectedRoute>
              },
              {
                path: paths.ticketsPreview,
                element: <ProtectedRoute><TicketsPreview /></ProtectedRoute>
              }
            ]
          },
          {
            path: rootPaths.registrationRoot,
            children: [
              {
                path: paths.customerRegistration,
                element: <ProtectedRoute><CustomerRegistration /> </ProtectedRoute>
              },
              {
                path: paths.customerDetails,
                element: <ProtectedRoute><CustomerDetails /></ProtectedRoute>
              },
              {
                path: paths.contactRegistration,
                element: <ProtectedRoute><ContactRegistration /></ProtectedRoute>
              },
              {
                path: paths.agentRegistration,
                element: <ProtectedRoute><AgentRegistration /></ProtectedRoute>
              },
              {
                path: paths.serviceGroupRegistration,
                element: <ProtectedRoute><ServiceGroupRegistration /></ProtectedRoute>
              },
              {
                path: paths.profileRegistration,
                element: <ProtectedRoute><ProfileRegistration /></ProtectedRoute>
              },
              {
                path: paths.serviceCatalogRegistration,
                element: <ProtectedRoute><ServiceCatalogRegistration /></ProtectedRoute>
              },
              {
                path: paths.serviceCatalogDetails,
                element: <ProtectedRoute><ServiceCatalogDetails /></ProtectedRoute>
              },
              {
                path: paths.typeTicketRegistration,
                element: <ProtectedRoute><TypeTicketRegistration /></ProtectedRoute>
              },
              {
                path: paths.emailBoxRegistration,
                element: <ProtectedRoute><EmailBoxRegistration /></ProtectedRoute>
              },
              {
                path: paths.holidayRegistration,
                element: <ProtectedRoute><HolidayRegistration /></ProtectedRoute>
              },
              {
                path: paths.slaPolicyList,
                element: <ProtectedRoute><SlaPolicyList /></ProtectedRoute>
              },
              {
                path: paths.slaPolicyEdit,
                element: <ProtectedRoute><SlaPolicyEdit /></ProtectedRoute>
              },
              {
                path: paths.businessHourRegistration,
                element: <ProtectedRoute><BusinessHourRegistration /></ProtectedRoute>
              },
              {
                path: paths.pauseReasonRegistration,
                element: <ProtectedRoute><PauseReasonRegistration /></ProtectedRoute>
              },
              {
                path: paths.scheduledTicketList,
                element: <ProtectedRoute><ScheduledTicketList /></ProtectedRoute>
              },
              {
                path: paths.scheduledTicketRegistration,
                element: <ProtectedRoute><ScheduledTicketRegistration /></ProtectedRoute>
              },
              {
                path: paths.scheduledTicketEdit,
                element: <ProtectedRoute><ScheduledTicketEdit /></ProtectedRoute>
              },
              {
                path: paths.assetTypeRegistration,
                element: <ProtectedRoute><AssetTypeRegistration /></ProtectedRoute>
              },
              {
                path: paths.assetTypeEdit,
                element: <ProtectedRoute><AssetTypeEdit /></ProtectedRoute>
              }
            ]
          },
          {
            path: rootPaths.userRoot,
            children: [
              {
                path: paths.profileUser,
                element: <ProtectedRoute><ProfileUser /></ProtectedRoute>
              }

            ]
          },
          {
            path: rootPaths.companyRoot,
            children: [
              {
                path: paths.myCompany,
                element: <ProtectedRoute><MyCompany /></ProtectedRoute>
              }

            ]
          },
          {
            path: rootPaths.reports,
            children: [
              {
                path: paths.reportExecutiveCustomer,
                element: <ProtectedRoute><ReportExecutiveCustomer /></ProtectedRoute>
              },
              {
                path: paths.productivityAnalysis,
                element: <ProtectedRoute><ReportProductivity /></ProtectedRoute>
              },
              {
                path: paths.reportOverview,
                element: <ProtectedRoute><ReportSupportDeskOverView /></ProtectedRoute>
              }
            ]
          },
          {
            path: rootPaths.integrations,
            children: [
              {
                path: paths.dattoRMM,
                element: <ProtectedRoute><DattoRMMIntegration /></ProtectedRoute>
              },
              {
                path: paths.mspTalks,
                element: <ProtectedRoute><MSPTalksIntegration /></ProtectedRoute>
              },
            ]
          },
          {
            path: rootPaths.assets,
            children: [
              {
                path: paths.assetList,
                element: <ProtectedRoute><AssetList /></ProtectedRoute>
              },
              {
                path: paths.assetEdit,
                element: <ProtectedRoute><AssetEdit /></ProtectedRoute>
              }
            ]
          },
          {
            path: rootPaths.alerts,
            children: [
              {
                path: paths.alertList,
                element: <ProtectedRoute><AlertList /></ProtectedRoute>
              }
            ]
          },
          {
            path: '*',
            element: <Navigate to={paths.error404} replace />
          }
        ]
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
