import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AlertDetailsModal from 'components/modals/AlertDetailsModal';
import Pagination from 'components/pagination';
import useApi from 'helpers/api';
import { formatDateTime } from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
const AlertList = () => {
  const api = useApi();

  const [showDetails, setShowDetails] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});

  const [alerts, setAlerts] = useState({ content: [] });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10
  });

  useEffect(() => {
    getAlerts();
  }, [filters]);

  const getAlerts = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.alerts.list(queryParams);

    if (response.content)
      setAlerts(response);
  };

  const columns = [
    {
      accessorKey: 'dattoSite',
      header: 'Cliente',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { dattoSite } = rowData.row.original;
        return (
          <span>{dattoSite?.customer?.name}</span>
        );
      }
    },
    {
      accessorKey: 'dattoDevice',
      header: 'Dispositivo',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { dattoDevice } = rowData.row.original;
        return (
          <span>{dattoDevice?.hostname}</span>
        );
      }
    },
    {
      accessorKey: 'alertDescription',
      header: 'Descrição',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { alertDescription } = rowData.row.original;
        return (
          <span>{alertDescription}</span>
        );
      }
    },
    {
      accessorKey: 'alertPriority',
      header: 'Prioridade',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { alertPriority } = rowData.row.original;
        return (
          <span>{alertPriority}</span>
        );
      }
    },
    {
      accessorKey: 'resolved',
      header: 'Resolvido',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { resolved } = rowData.row.original;
        return resolved
          ? (<span className='text-success'>Sim</span>)
          : (<span className='text-danger'>Não</span>)
      }
    },
    {
      accessorKey: 'creationDate',
      header: 'Data',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { creationDate } = rowData.row.original;
        return (
          <span>{creationDate ? formatDateTime(creationDate) : '---'}</span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const alert = rowData.row.original;
        return (
          <Button size='sm' variant='link' onClick={() => {
            setSelectedAlert(alert);
            setShowDetails(true);
          }}>
            <FontAwesomeIcon icon={faEye} size='sm' />
          </Button>
        );
      }
    },
  ];

  const table = useAdvanceTable({
    data: alerts.content,
    columns
  });

  return (
    <>
      <AlertDetailsModal
        show={showDetails}
        close={() => setShowDetails(false)}
        alert={selectedAlert} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Alertas" />
        <FalconComponentCard.Body className="bg-white">
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              table
              headerClassName="bg-100 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableProvider>
          <Pagination
            data={alerts}
            filters={filters}
            setFilters={setFilters}
          />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default AlertList;