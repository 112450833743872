import { faChevronDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Editor from "components/editor/Editor";
import Loading from "components/loading/Loading";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { forwardRef, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";

const ReplyNoteOffcanvas = ({ show, handleClose, ticketId, contact, refreshData, refreshNotes, refreshLogs }) => {
  const api = useApi();
  const form = useRef(null);
  const [validated, setValidated] = useState(false);
  const [body, setBody] = useState({
    note: '',
    type: 'PUBLIC',
    cc: []
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const [cc, setCc] = useState('');

  useEffect(() => {
    if (show) {
      clearFields();
      setValidated(false);
    }
  }, [show]);

  useEffect(() => {
    console.log(body);
  }, [body]);

  useEffect(() => {
    const emailsArray = cc
      .split(';')
      .map(email => email.trim())
      .filter(email => email);

    setBody({ ...body, cc: emailsArray });
  }, [cc]);

  const onSubmit = async e => {
    setLoading(true);
    if (form.current.checkValidity() === false || body.note.trim() === '' || body.note === '<p><br></p>') {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response = await api.ticketNotes.reply(ticketId, body);
      handleResponse(response);
      clearFields();
      handleClose();
    }
    setLoading(false);
  };

  const replyAndSetAsPending = async e => {
    setLoading(true);
    if (form.current.checkValidity() === false || body.note.trim() === '' || body.note === '<p><br></p>') {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response = await api.ticketNotes.replyAndSetAsPending(ticketId, body);
      handleResponse(response);
      refreshData();
      clearFields();
      handleClose();
    }
    setLoading(false);
  };

  const replyAndSetAsCompleted = async e => {
    setLoading(true);
    if (form.current.checkValidity() === false || body.note.trim() === '' || body.note === '<p><br></p>') {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response = await api.ticketNotes.replyAndSetAsCompleted(ticketId, body);
      handleResponse(response);
      refreshData();
      clearFields();
      handleClose();
    }
    setLoading(false);
  };

  const handleSetBody = (fieldName, fieldValue) => {
    setBody(prevBody => ({ ...prevBody, [fieldName]: fieldValue }));
  };

  const clearFields = () => {
    setBody({
      note: '',
      type: 'PUBLIC',
      cc: []
    });
  };

  function handleResponse(response) {
    if (response.id) {
      setShowSuccess(true);
      refreshLogs();
      refreshNotes();
    } else {
      setError(response);
      setShowError(true);
    }
  }

  return (
    <>
      {loading && <Loading />}
      <SuccessRequest
        message="Nota adicionada com sucesso"
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error.description}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <Offcanvas show={show} onHide={handleClose} placement='end' style={{ minWidth: '45%', maxWidth: '90%' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Responder</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form ref={form} validated={validated}>
            <div className="mb-2 mt-n2">
              <Form.Label className="mb-1 fs-10">Para <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                value={contact && contact.email}
                disabled
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Selecione um tipo de visualização.</Form.Control.Feedback>
            </div>
            <div className="mb-2 mt-2">
              <Form.Label className="mb-1 me-1 fs-10">Cc</Form.Label>
              <OverlayTrigger overlay={<Tooltip>Separe os e-mails por ponto e vírgula para enviar para mais de uma pessoa</Tooltip>}>
                <span><FontAwesomeIcon icon={faInfoCircle} size="sm" /></span>
              </OverlayTrigger>
              <Form.Control
                type="text"
                value={cc}
                onChange={e => setCc(e.target.value)} />
            </div>
            <div className="mb-2">
              <Form.Label className="mb-1 fs-10">Descrição <span className="text-danger">*</span></Form.Label>
              <Editor value={value => handleSetBody('note', value)} height="50vh" />
            </div>
          </Form>
          <br /><br />

          <footer className="fixed-bottom p-2 d-flex justify-content-end">
            <Button variant="falcon-default" type="submit" className="me-2" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" type="submit" onClick={onSubmit} style={{ borderRadius: '5px 0 0 5px' }}>
              Salvar
            </Button>

            <Dropdown drop='up'>
              <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

              <Dropdown.Menu className="flex-wrap overflow-x-scroll" style={{ width: '150px' }}>
                <Dropdown.Item onClick={() => replyAndSetAsPending()}>Salvar e Pausar</Dropdown.Item>
                <Dropdown.Item onClick={() => replyAndSetAsCompleted()}>Salvar e Concluir</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </footer>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const CustomToggle = forwardRef(({ onClick }, ref) => (
  <Button
    style={{ borderRadius: '0 5px 5px 0', height: '100%' }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon={faChevronDown} />
  </Button>
));

export default ReplyNoteOffcanvas;
