import { formatDateTime } from "helpers/utils";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AlertDetailsModal = ({ show, close, alert }) => {
  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Detalhes do Alerta</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* Seção de Informações Gerais */}
        <h5 className="mb-3">Informações Gerais</h5>
        <Row className="mb-3">
          <Col md={6}>
            <h6>Categoria</h6>
            <p>{alert.alertCategory}</p>
          </Col>
          <Col md={6}>
            <h6>Prioridade</h6>
            <p>{alert.alertPriority}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <h6>Tipo</h6>
            <p>{alert.alertType}</p>
          </Col>
          <Col md={6}>
            <h6>UID do Alerta</h6>
            <p>{alert.alertUid}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <h6>Descrição</h6>
            <p>{alert.alertDescription}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <h6>Data de Criação</h6>
            <p>{formatDateTime(alert.creationDate)}</p>
          </Col>
          <Col md={6}>
            <h6>Última Atualização</h6>
            <p>{formatDateTime(alert.lastUpdateDate)}</p>
          </Col>
        </Row>

        <hr />

        {/* Seção de Diagnóstico */}
        <h5 className="mb-3">Diagnóstico</h5>
        <Row className="mb-3">
          <Col>
            <pre style={{ backgroundColor: "#f8f9fa", padding: "10px", borderRadius: "5px", maxHeight: "200px", overflowY: "auto" }}>
              {alert.alertDiagnostic}
            </pre>
          </Col>
        </Row>

        <hr />

        {/* Seção de Dispositivo e Local */}
        <h5 className="mb-3">Dispositivo e Local</h5>
        <Row className="mb-3">
          <Col md={6}>
            <h6>Nome do Dispositivo</h6>
            {alert.dattoDevice
              ? <p>{alert?.dattoDevice?.hostname}</p>
              : <p>{alert.deviceName} (Dispositivo não criado)</p>
            }
          </Col>
          <Col md={6}>
            <h6>UID do Dispositivo</h6>
            <p>{alert.deviceUid}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <h6>Site</h6>
            <p>{alert.siteName}</p>
          </Col>
          <Col md={6}>
            <h6>UID do Site</h6>
            <p>{alert.siteUid}</p>
          </Col>
        </Row>

        <hr />

        {/* Seção de Ticket */}
        <h5 className="mb-3">Ticket</h5>
        <Row>
          <Col>
            {alert.ticket ? (
              <Link to={`/tickets/${alert.ticket.id}`} target="_blank">#{alert.ticket.code} {alert.ticket.subject}</Link>
            ) : (
              <p>Nenhum ticket associado.</p>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertDetailsModal;