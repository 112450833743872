import FalconCardHeader from 'components/common/FalconCardHeader';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

const ChangePassword = () => {
  const api = useApi();
  const form = useRef(null);

  const [userId, setUserId] = useState('');
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    getUserIdAuthenticated();
  }, []);

  const getUserIdAuthenticated = async () => {
    let response = await api.agents.getUserIdAuthenticated();
    if (response)
      setUserId(response);
  };


  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let request = await api.agents.updatePassword(userId, formData);
      if (request.ok) {
        setShowAlert(true);
        clearFields();
      } else {
        setError(request);
        setShowAlertError(true);
      }
    }
  };

  const clearFields = () => {
    setFormData({
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
  };

  return (
    <>
      <SuccessRequest
        message="Senha alterada com sucesso!"
        showAlert={showAlert}
        setShowAlert={() => setShowAlert(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showAlertError}
        setShowAlert={() => setShowAlertError(false)} />
      <Card className="mb-3">
        <FalconCardHeader title="Alterar senha" />
        <Card.Body>
          <Form validated={validated} ref={form}>
            <Form.Group className="mb-3" controlId="currentPassword">
              <Form.Label>Senha atual</Form.Label>
              <Form.Control
                type="password"
                value={formData.currentPassword}
                name="currentPassword"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>Nova senha</Form.Label>
              <Form.Control
                type="password"
                value={formData.newPassword}
                name="newPassword"
                pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=\S+$).{8,}$"
                onChange={handleChange}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">A senha deve ter 8 caracteres, incluindo pelo menos uma letra maiúscula, um número e um caractere especial.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>Confirmar senha</Form.Label>
              <Form.Control
                type="password"
                value={formData.confirmPassword}
                name="confirmPassword"
                pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=\S+$).{8,}$"
                onChange={handleChange}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">A senha deve ter 8 caracteres, incluindo pelo menos uma letra maiúscula, um número e um caractere especial.</Form.Control.Feedback>
            </Form.Group>
            <Button className="w-100" onClick={handleSubmit}>
              Atualizar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ChangePassword;
