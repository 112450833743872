import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import datto24 from '../../assets/img/integrations/Datto_id5WkljiWh_2.png';
import msptalks from '../../assets/img/integrations/vertical.png';

const IntegrationList = ({ reportName }) => {
  const integrations = [
    {
      name: "Datto RMM",
      value: '/integrations/datto-rmm',
      img: datto24
    },
    {
      name: "MSP Talks",
      value: '/integrations/msptalks',
      img: msptalks
    }
  ];

  const location = useLocation();
  const initialIntegration = integrations.find(report => report.value === location.pathname) || integrations[0];

  const [selectedIntegration, setSelectedIntegration] = useState(initialIntegration);
  const navigate = useNavigate();

  useEffect(() => {
    reportName(selectedIntegration.name);
    if (selectedIntegration.value !== location.pathname) {
      navigate(selectedIntegration.value);
    }
  }, [selectedIntegration, reportName, navigate, location.pathname]);

  return (
    <FalconComponentCard className="position-sticky top-0" style={{ height: '85vh' }}>
      <FalconComponentCard.Header title="Integrações" />
      <Card.Body className="p-0">
        <Flex direction="column" alignItems="start">
          {integrations.map(item => (
            <Button
              key={item.value}
              onClick={() => setSelectedIntegration(item)}
              variant="link"
              size="sm"
              className={`d-flex align-items-center w-100 py-2 rounded text-body${selectedIntegration.name === item.name ? ' bg-100' : ''}`}
              style={{ textDecoration: 'none' }}
            >
              {item.icon
                ? <FontAwesomeIcon icon={item.icon} className={`me-2${selectedIntegration.name === item.name ? ' text-primary' : ' text-secondary'}`} />
                : <img src={item.img} className="w-25 me-2" />

              }
              {item.name}
            </Button>
          ))}
        </Flex>
      </Card.Body>
    </FalconComponentCard>
  );
};

export default IntegrationList;
