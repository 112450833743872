import { faExclamationTriangle, faInfoCircle, faSave, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import SubtleBadge from "components/common/SubtleBadge";
import Loading from "components/loading/Loading";
import Pagination from "components/pagination";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { formatDateInFromNow } from "helpers/utils";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import RunNewAnalysisModal from "../../modals/RunNewAnalysisModal";

const api = useApi();

const DattoSites = ({ created }) => {
  const DEFAULT_COOLDOWN_MINUTES = 60;

  const [sites, setSites] = useState({ content: [] });
  const [filters, setFilters] = useState({ page: 0, size: 10 });
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerMap, setSelectedCustomerMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showAnalysisTool, setShowAnalysisTool] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [remainingTime, setRemainingTime] = useState(null);
  const [lastLog, setLastLog] = useState({ syncDate: '', syncStatus: { color: '', description: '' } });

  useEffect(() => {
    fetchSites();
  }, [filters, created]);

  useEffect(() => {
    initializeCustomers();
    fetchLastLog();
  }, []);

  useEffect(() => {
    initializeCooldown();
  }, []);

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setInterval(updateRemainingTime, 60000);
      return () => clearInterval(timer);
    }
  }, [remainingTime]);

  const fetchSites = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.dattoSites.find(queryParams);
    setSites(response || { content: [] });
    initializeSelectedCustomerMap(response?.content || []);
  };

  const fetchLastLog = async () => {
    const response = await api.dattoSites.logs.findLastLog();
    setLastLog(response.id ? response : {});
  };

  const initializeSelectedCustomerMap = (sitesContent) => {
    const initialSelected = sitesContent.reduce((acc, site) => {
      acc[site.id] = site.customer ? site.customer.id : '';
      return acc;
    }, {});
    setSelectedCustomerMap(initialSelected);
  };

  const forceSync = async () => {
    setLoading(true);
    const request = await api.dattoSync.sites();

    if (request.ok) {
      onSyncSuccess();
    } else {
      const response = await request.json();
      handleSyncError(response.userMessage);
    }

    setLoading(false);
  };

  const onSyncSuccess = () => {
    fetchSites();
    setRemainingTime(DEFAULT_COOLDOWN_MINUTES);
    storeCooldown(DEFAULT_COOLDOWN_MINUTES);
    setSuccess("Sincronização feita com sucesso");
    setShowSuccess(true);
  };

  const handleSyncError = (errorMessage) => {
    setError(errorMessage);
    setShowError(true);
    calculateRemainingTimeFromError(errorMessage);
  };

  const calculateRemainingTimeFromError = (errorMessage) => {
    const match = errorMessage.match(/(\d+) minutos/);
    if (match) {
      const minutesLeft = parseInt(match[1], 10);
      setRemainingTime(minutesLeft);
      storeCooldown(minutesLeft);
    }
  };

  const storeCooldown = (minutesLeft) => {
    localStorage.setItem('syncCooldown', JSON.stringify({
      minutesLeft,
      timestamp: Date.now()
    }));
  };

  const updateRemainingTime = () => {
    setRemainingTime(prevTime => {
      const newTime = prevTime - 1;
      if (newTime <= 0) {
        localStorage.removeItem('syncCooldown');
      }
      return newTime;
    });
  };

  const initializeCooldown = () => {
    const storedCooldown = localStorage.getItem('syncCooldown');
    if (storedCooldown) {
      const { minutesLeft, timestamp } = JSON.parse(storedCooldown);
      const elapsedTime = Math.floor((Date.now() - timestamp) / 60000);
      const updatedMinutesLeft = minutesLeft - elapsedTime;
      if (updatedMinutesLeft > 0) {
        setRemainingTime(updatedMinutesLeft);
      } else {
        localStorage.removeItem('syncCooldown');
      }
    }
  };

  const initializeCustomers = async () => {
    const filterCustomer = { page: 0, size: 1000 };
    const queryParams = new URLSearchParams(filterCustomer).toString();
    const response = await api.customers.list(queryParams);
    setCustomers(response.content || []);
  };

  const handleSelectChange = useCallback((siteId, selectedCustomerId) => {
    setSelectedCustomerMap(prevState => ({
      ...prevState,
      [siteId]: selectedCustomerId
    }));
  }, []);

  const handleSave = async () => {
    const { associations, disassociations } = getAssociationsAndDisassociations();

    const associateSuccess = associations.length > 0 && await batchProcessAssociations(associations);
    const disassociateSuccess = disassociations.length > 0 && await batchProcessDisassociations(disassociations);

    if (associateSuccess || disassociateSuccess) {
      fetchSites();
    }
  };

  const getAssociationsAndDisassociations = () => {
    const associations = [];
    const disassociations = [];

    sites.content.forEach(site => {
      const originalCustomer = site.customer?.id || null;
      const selectedCustomer = selectedCustomerMap[site.id] || null;

      if (selectedCustomer && selectedCustomer !== originalCustomer) {
        associations.push({ siteId: site.id, customerId: selectedCustomer });
      }

      if (originalCustomer && !selectedCustomer) {
        disassociations.push(site.id);
      }
    });

    return { associations, disassociations };
  };

  const batchProcessAssociations = async (associations) => {
    const response = await api.dattoSites.batchAssociate(associations);
    if (response.ok) {
      setSuccess("Mapeamento alterado com sucesso");
      setShowSuccess(true);
      return true;
    } else {
      setError(response.userMessage);
      setShowError(true);
      return false;
    }
  };

  const batchProcessDisassociations = async (disassociations) => {
    const response = await api.dattoSites.batchDisassociate(disassociations);
    if (response.ok) {
      setSuccess("Mapeamento alterado com sucesso");
      setShowSuccess(true);
      return true;
    } else {
      setError(response.userMessage);
      setShowError(true);
      return false;
    }
  };

  return (
    <>
      {loading && <Loading />}
      <SuccessRequest message={success} showAlert={showSuccess} setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest message={error} showAlert={showError} setShowAlert={() => setShowError(false)} />
      <RunNewAnalysisModal show={showAnalysisTool} close={() => setShowAnalysisTool(false)} refreshData={fetchSites} />

      <Row className="justify-content-between">
        <LastSyncStatus lastLog={lastLog} />
        <ActionButtons
          remainingTime={remainingTime}
          onForceSync={forceSync}
          onSave={handleSave}
          showAnalysisTool={() => setShowAnalysisTool(true)}
        />
      </Row>

      <hr />

      <Table responsive>
        <SitesTable
          sites={sites}
          customers={customers}
          selectedCustomerMap={selectedCustomerMap}
          onCustomerChange={handleSelectChange}
          onReload={fetchSites}
        />
      </Table>

      <Pagination data={sites} filters={filters} setFilters={setFilters} />

      <Flex className="w-100" justifyContent="end">
        <Button variant="primary" size="sm" onClick={handleSave}>
          <FontAwesomeIcon icon={faSave} className="me-1" />
          Salvar
        </Button>
      </Flex>
    </>
  );
};

const LastSyncStatus = ({ lastLog }) => (
  lastLog.syncStatus ? (
    <Col className="col-auto">
      <span className="fs-10 fw-bold me-1">Última sincronização</span>
      <SubtleBadge bg={lastLog.syncStatus.color} pill>{lastLog.syncStatus.description}</SubtleBadge>
      <span className="fs-10 ms-1">{formatDateInFromNow(lastLog.syncDate)}</span>
      <OverlayTrigger overlay={<Tooltip>A sincronização de Sites é feita todos os dias às 00:00, horário de Brasilia.</Tooltip>}>
        <span>
          <FontAwesomeIcon icon={faInfoCircle} size="sm" className="ms-2" />
        </span>
      </OverlayTrigger>
    </Col>
  ) : (
    <Col className="col-auto">
      <span>Não há logs de sincronização</span>
    </Col>
  )
);

const ActionButtons = ({ remainingTime, onForceSync, onSave, showAnalysisTool }) => (
  <Col className="col-auto" as={Flex}>
    <Button size="sm" className="me-2" onClick={onForceSync} disabled={remainingTime !== null && remainingTime > 0}>
      <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" />
      {remainingTime !== null && remainingTime > 0
        ? `Nova sinc. disponível em ${remainingTime} minutos`
        : 'Sincronizar Sites'}
    </Button>
    <Button variant="primary" size="sm" className="me-2" onClick={showAnalysisTool}>
      <FontAwesomeIcon icon={faSyncAlt} className="me-1" />
      Executar uma nova análise
    </Button>
    <Button variant="primary" size="sm" onClick={onSave}>
      <FontAwesomeIcon icon={faSave} className="me-1" />
      Salvar
    </Button>
  </Col>
);

const SitesTable = ({ sites, customers, selectedCustomerMap, onCustomerChange, onReload }) => (
  <>
    <thead>
      <tr>
        <th className="text-900 fs-10">Site Datto RMM</th>
        <th className="w-75 text-900 fs-10">Cliente MSP Desk</th>
        <th>
          <Button variant="link" size="sm" onClick={onReload}>
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        </th>
      </tr>
    </thead>
    <tbody>
      {sites.content.map(site => (
        <tr key={site.id}>
          <td className="fs-10">{site.name}</td>
          <td>
            <Form.Select value={selectedCustomerMap[site.id] || ''} onChange={e => onCustomerChange(site.id, e.target.value)}>
              <option value="" className="text-danger">Não mapeado</option>
              {customers.map(item => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </Form.Select>
          </td>
        </tr>
      ))}
    </tbody>
  </>
);

export default DattoSites;
