import { faInfoCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import MultiSelect from "components/common/MultiSelect";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { PrioritySelect } from "hooks/useTicketsTable";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import MaskedInput from "react-text-mask";

const SlaPolicyEdit = () => {
  const { id } = useParams();
  const api = useApi();
  const form = useRef(null);
  const navigate = useNavigate();
  const modelBody = {
    name: '',
    description: '',
    slaEscalations: [],
    slaTargets: [
      {
        priority: 'PLANNED',
        responseTime: '01:00',
        resolutionTime: '01:00',
        operationalHours: 'CALENDAR',
        escalation: true
      },
      {
        priority: 'LOW',
        responseTime: '01:00',
        resolutionTime: '01:00',
        operationalHours: 'CALENDAR',
        escalation: true
      },
      {
        priority: 'MEDIUM',
        responseTime: '01:00',
        resolutionTime: '01:00',
        operationalHours: 'CALENDAR',
        escalation: true
      },
      {
        priority: 'HIGH',
        responseTime: '01:00',
        resolutionTime: '01:00',
        operationalHours: 'CALENDAR',
        escalation: true
      },
      {
        priority: 'CRITICAL',
        responseTime: '01:00',
        resolutionTime: '01:00',
        operationalHours: 'CALENDAR',
        escalation: true
      },
    ],
  };

  const [validated, setValidated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [success, setSuccess] = useState("");
  const [error, setError] = useState({});

  const [formattedUsers, setFormattedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [escalations, setEscalations] = useState([]);
  const [body, setBody] = useState(modelBody);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getData();
  }, [formattedUsers]);

  useEffect(() => {
    const usersArray = [{ value: "assigned_agent", label: "Agente atribuído" }, ...users.map(item => ({
      value: item.email,
      label: item.firstName
    }))];
    setFormattedUsers(usersArray);
  }, [users]);

  useEffect(() => {
    console.log(body.slaTargets);
  }, [body.slaTargets]);

  const getData = async () => {
    setBody(modelBody);
    const response = await api.slaPolicies.findById(id);
    if (!response.status) {
      setBody(prevBody => ({
        ...prevBody,
        name: response.name,
        description: response.description,
        slaTargets: response.slaTargets
      }));

      const reminders = response.slaEscalations.filter(item => item.escalationType === 'DUE_TIME_APPROACHES');
      const escalations = response.slaEscalations.filter(item => item.escalationType === 'VIOLATED');

      if (reminders.length > 0) {
        for (let i = 0; i < reminders.length; i++) {
          let emails = reminders[i].sendTo.trim().split(";")
            .map(email => email.trim().replace(';', ''))
            .filter(Boolean);

          reminders[i].sendTo = formattedUsers.filter(user => emails.includes(user.value));
        }
      }

      if (escalations.length > 0) {
        for (let i = 0; i < escalations.length; i++) {
          let emailsFound = escalations[i].sendTo.trim().split(";")
            .map(email => email.trim().replace(';', ''))
            .filter(Boolean);

          escalations[i].sendTo = formattedUsers.filter(user => emailsFound.includes(user.value));
        }
      }

      setReminders(reminders);
      setEscalations(escalations);
    }
  };

  const getUsers = async () => {
    const response = await api.agents.listSummaryData();
    if (!response.status)
      setUsers(response);
  };

  const transformDataForBackend = (data) => {
    return {
      name: data.name,
      description: data.description,
      slaTargets: data.slaTargets.map(target => ({
        id: target.id,
        priority: target.priority.priority,
        responseTime: target.responseTime,
        resolutionTime: target.resolutionTime,
        operationalHours: target.operationalHours,
        escalation: target.escalation,
      })),
      slaEscalations: [
        ...reminders.map(reminder => ({
          id: reminder.id,
          escalationType: reminder.escalationType,
          whenType: reminder.whenType,
          sendIn: reminder.sendIn,
          sendTo: reminder.sendTo.map(user => ({
            label: user.label,
            value: user.value,
          })),
        })),
        ...escalations.map(escalation => ({
          id: escalation.id,
          escalationType: escalation.escalationType,
          whenType: escalation.whenType,
          sendIn: escalation.sendIn,
          sendTo: escalation.sendTo.map(user => ({
            label: user.label,
            value: user.value,
          })),
        })),
      ],
    };
  };

  const handleSubmit = async e => {
    addEscalationInBody(reminders, body, setBody, escalations);
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      const transformedBody = transformDataForBackend(body);
      const response = await api.slaPolicies.edit(id, transformedBody);

      if (response.status) {
        setError(response);
        setShowError(true);
      } else {
        setSuccess("Política editada com sucesso");
        setShowSuccess(true);
        getData();
      }
    }
  };

  const deleteSlaEscalation = async (slaEscalationId) => {
    const request = await api.slaPolicies.deleteEscalation(id, slaEscalationId);

    if (request.ok) {
      setSuccess("Escalonamento excluído com sucesso");
      setShowSuccess(true);
      getData();
    } else {
      setError(request);
      setShowError(true);
    }
  };

  const handleSetBody = (fieldName, fieldValue, slaTargetIndex, slaEscalationIndex) => {
    setBody(prevBody => {
      if (slaTargetIndex === undefined && slaEscalationIndex === undefined) {
        return { ...prevBody, [fieldName]: fieldValue };
      }

      if (slaEscalationIndex !== undefined) {
        const updatedEscalations = [...prevBody.slaEscalations];
        updatedEscalations[slaEscalationIndex] = {
          ...updatedEscalations[slaEscalationIndex],
          [fieldName]: fieldValue
        };

        return { ...prevBody, slaEscalations: updatedEscalations };
      }

      const updatedSlaTargets = [...prevBody.slaTargets];
      updatedSlaTargets[slaTargetIndex] = {
        ...updatedSlaTargets[slaTargetIndex],
        [fieldName]: fieldValue
      };

      return { ...prevBody, slaTargets: updatedSlaTargets };
    });
  };

  const handleChangeReminders = (fieldName, fieldValue, index) => {
    setReminders(prevBody => {
      const updatedReminders = [...prevBody];
      updatedReminders[index] = {
        ...updatedReminders[index],
        [fieldName]: fieldValue
      };

      return updatedReminders;
    });
  };

  const handleChangeEscalations = (fieldName, fieldValue, index) => {
    setEscalations(prevBody => {
      const updatedEscalations = [...prevBody];
      updatedEscalations[index] = {
        ...updatedEscalations[index],
        [fieldName]: fieldValue
      };

      return updatedEscalations;
    });
  };

  const addNewReminder = () => {
    const newReminder = {
      escalationType: "DUE_TIME_APPROACHES",
      whenType: 'RESPONSE_TARGET',
      sendIn: '',
      sendTo: []
    };
    const newReminders = [...reminders, newReminder];
    setReminders(newReminders);
  };

  const addNewEscalation = () => {
    const newEscalation = {
      escalationType: "VIOLATED",
      whenType: 'RESPONSE_TARGET',
      sendIn: '',
      sendTo: []
    };
    const newEscalations = [...escalations, newEscalation];
    setEscalations(newEscalations);
  };

  const deleteEscalation = index => {
    const slaEscalations = body.slaEscalations;
    slaEscalations.splice(index, 1);
    setBody(prevBody => ({ ...prevBody, [slaEscalations]: slaEscalations }));

    escalations.splice(index);
    setEscalations(escalations);
  };

  const deleteReminder = index => {
    const slaEscalations = body.slaEscalations;
    slaEscalations.splice(index, 1);
    setBody(prevBody => ({ ...prevBody, [slaEscalations]: slaEscalations }));

    reminders.splice(index);
    setReminders(reminders);
  };

  const addEscalationInBody = (reminders, body, setBody, escalations) => {
    if (reminders.length > 0) {
      const slaEscalations = body.slaEscalations;
      slaEscalations.push(...reminders);
      setBody(prevBody => ({ ...prevBody, [slaEscalations]: slaEscalations }));
    }
    if (escalations.length > 0) {
      const slaEscalations = body.slaEscalations;
      slaEscalations.push(...escalations);
      setBody(prevBody => ({ ...prevBody, [slaEscalations]: slaEscalations }));
    }
  }


  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message={success} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <Row>
        <Col md={9}>
          <FalconComponentCard>
            <FalconComponentCard.Header title="Editar política de SLA">
              <Flex justifyContent="start" alignItems="center">
                <IconButton
                  onClick={() => navigate(-1)}
                  variant="falcon-default"
                  size="sm"
                  icon="arrow-left"
                  className="me-2"
                />
              </Flex>
            </FalconComponentCard.Header>

            <FalconComponentCard.Body>
              <Form validated={validated} ref={form}>
                <Form.Group className="w-75 mb-3">
                  <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nome"
                    required
                    value={body.name}
                    onChange={e => handleSetBody('name', e.target.value)} />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um nome</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="w-75 mb-3">
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    name="description"
                    required
                    value={body.description}
                    onChange={e => handleSetBody('description', e.target.value)}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira uma descrição</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Definir a meta do SLA</Form.Label>
                  <div className="table-responsive scrollbar">
                    <table className="table table-bordered table-striped fs-10 mb-0">
                      <thead className="bg-200 text-900">
                        <tr>
                          <th>Prioridade</th>
                          <th>Tempo de primeira resposta</th>
                          <th>Tempo de resolução</th>
                          <th className="text-nowrap">
                            Horário de funcionamento {" "}
                            <OverlayTrigger overlay={<Tooltip>Para usar a opção Horário Comercial é necessário configurar em {"Configurações > Horário de funcionamento"}</Tooltip>}>
                              <span><FontAwesomeIcon icon={faInfoCircle} /></span>
                            </OverlayTrigger>
                          </th>
                          <th>Escalonamento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {body.slaTargets.map((item, i) => (
                          <tr>
                            <td>
                              <PrioritySelect
                                title={item.priority?.description}
                                color={item.priority?.color}
                                data={item.priority?.data} />
                            </td>
                            <td>
                              <MaskedInput
                                mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                                placeholder="HH:MM"
                                required
                                guide={false}
                                value={item.responseTime}
                                onChange={e => handleSetBody('responseTime', e.target.value, i)}
                                render={(ref, props) => (
                                  <Form.Control ref={ref} {...props} />
                                )}
                              />
                            </td>
                            <td>
                              <MaskedInput
                                mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                                placeholder="HH:MM"
                                required
                                guide={false}
                                value={item.resolutionTime}
                                onChange={e => handleSetBody('resolutionTime', e.target.value, i)}
                                render={(ref, props) => (
                                  <Form.Control ref={ref} {...props} />
                                )}
                              />
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Horas operacionais"
                                className="flex-1 me-2"
                                required
                                value={item.operationalHours}
                                onChange={e => handleSetBody('operationalHours', e.target.value, i)}>
                                <option value="CALENDAR" key="CALENDAR">Horas corridas</option>
                                <option value="BUSINESS_HOURS" key="BUSINESS_HOURS">Horário comercial</option>
                              </Form.Select>
                            </td>
                            <td>
                              <Form.Check
                                type='switch'
                                id='defaultSwitch'
                                defaultChecked={item.escalation}
                                value={item.escalation}
                                onClick={() => handleSetBody('escalation', !item.escalation, i)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Alertar os agentes quando o prazo do SLA estiver próximo</Form.Label>
                  <Card>
                    {reminders.length === 0 &&
                      <Card.Body className="bg-100 d-flex justify-content-center align-items-center">
                        <p className="fs-10">Criar um lembrete para os agentes para quando o prazo do SLA estiver próximo.</p>
                      </Card.Body>
                    }
                    {reminders.length > 0 && reminders.map((item, i) => (
                      <Card.Body className="bg-100" key={i}>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={5}>
                            <Form.Label>Quando</Form.Label>
                            <Form.Select
                              required
                              value={item.whenType}
                              onChange={e => handleChangeReminders('whenType', e.target.value, i)}
                            >
                              <option value="RESPONSE_TARGET">Meta da primeira resposta</option>
                              <option value="RESOLUTION_TARGET">Meta de resolução</option>
                            </Form.Select>
                            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Selecione uma opção</Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} md={5}>
                            <Form.Label>Aproxima-se em</Form.Label>
                            <MaskedInput
                              mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                              placeholder="HH:MM"
                              required
                              guide={false}
                              value={item.sendIn}
                              onChange={e => handleChangeReminders('sendIn', e.target.value, i)}
                              render={(ref, props) => (
                                <Form.Control ref={ref} {...props} />
                              )}
                            />
                            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Preencha este campo</Form.Control.Feedback>
                          </Form.Group>
                          <Col md={2}>
                            <Button
                              variant="outline-danger"
                              className="border border-0 p-1 rounded"
                              size="sm"
                              tabIndex={-1}
                              onClick={() => item.id ? deleteSlaEscalation(item.id) : deleteReminder(i)}>
                              <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Label>Enviar lembrete para</Form.Label>
                            <MultiSelect
                              value={item.sendTo}
                              options={formattedUsers}
                              placeholder='Selecione os agentes'
                              onChange={selectedOptions => handleChangeReminders('sendTo', selectedOptions, i)} />
                            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Preencha este campo</Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <hr />
                      </Card.Body>
                    ))}
                    <Card.Footer>
                      <Button variant="link" size="sm" onClick={() => addNewReminder()}>
                        <FontAwesomeIcon icon="plus" size="sm" className="me-2" />
                        Adicionar novo lembrete
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Enviar escalonamento quando o SLA for violado</Form.Label>
                  <Card>
                    {escalations.length === 0 &&
                      <Card.Body className="bg-100 d-flex justify-content-center align-items-center">
                        <p className="fs-10">Definir escalonamento para quando o SLA for violado.</p>
                      </Card.Body>
                    }
                    {escalations.length > 0 && escalations.map((item, i) => (
                      <Card.Body className="bg-100" key={i}>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={5}>
                            <Form.Label>Quando</Form.Label>
                            <Form.Select
                              required
                              value={item.whenType}
                              onChange={e => handleChangeEscalations('whenType', e.target.value, i)}
                            >
                              <option value="RESPONSE_TARGET">Meta da primeira resposta</option>
                              <option value="RESOLUTION_TARGET">Meta de resolução</option>
                            </Form.Select>
                            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Selecione uma opção</Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} md={5}>
                            <Form.Label>Não for cumprido, escalonar em</Form.Label>
                            <MaskedInput
                              mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                              placeholder="HH:MM"
                              required
                              guide={false}
                              value={item.sendIn}
                              onChange={e => handleChangeEscalations('sendIn', e.target.value, i)}
                              render={(ref, props) => (
                                <Form.Control ref={ref} {...props} />
                              )}
                            />
                            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Preencha este campo</Form.Control.Feedback>
                          </Form.Group>
                          <Col md={2}>
                            <Button
                              variant="outline-danger"
                              className="border border-0 p-1 rounded"
                              size="sm"
                              tabIndex={-1}
                              onClick={() => item.id ? deleteSlaEscalation(item.id) : deleteEscalation(i)}>
                              <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Label>Enviar para</Form.Label>
                            <MultiSelect
                              value={item.sendTo}
                              options={formattedUsers}
                              placeholder='Selecione os agentes'
                              onChange={selectedOptions => handleChangeEscalations('sendTo', selectedOptions, i)} />
                            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Preencha este campo</Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <hr />
                      </Card.Body>
                    ))}
                    <Card.Footer>
                      <Button variant="link" size="sm" onClick={() => addNewEscalation()}>
                        <FontAwesomeIcon icon="plus" size="sm" className="me-2" />
                        Adicionar novo escalonamento
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form.Group>

                <Form.Group className="d-flex justify-content-end">
                  <Link to="/registration/sla-policies" className="btn btn-default me-2">Cancelar</Link>
                  <Button variant="primary" onClick={handleSubmit}>Salvar</Button>
                </Form.Group>
              </Form>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>

        <Col>
          <FalconComponentCard>
            <FalconComponentCard.Body>
              <p className="fw-bold">Política de SLA</p>
              <p className="fs-10">Uma política de Acordo de Nível de Serviço (SLA) permite definir padrões de desempenho para sua equipe de suporte. Você pode definir políticas de SLA para o tempo em que os agentes devem responder e resolver tickets com base em prioridades. Você pode escolher se deseja que cada regra de SLA seja calculada com base em horas corridas ou comerciais. Suas políticas de SLA serão usadas no MSP DESK para determinar o prazo de vencimento de cada ticket.</p>
              <br />
              <p className="fw-bold">Lembretes de SLA</p>
              <p className="fs-10">Você pode configurar lembretes para garantir que os agentes sejam notificados sobre a proximidade do vencimento dos tickets. Os lembretes podem ser para a resposta e resolução de tickets.</p>
              <br />
              <p className="fw-bold">Notificações de violação de SLA</p>
              <p className="fs-10">Você também pode definir regras de escalonamento que notificam os agentes ou gerentes quando os SLAs forem violados.</p>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>
      </Row>
    </>
  );
};

export default SlaPolicyEdit;