import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import LayoutIntegration from "../LayoutIntegration";
import Settings from "./Settings";

const MSPTalksIntegration = () => {
  return (
    <LayoutIntegration>
      <Row>
        <Col xxl={12}>
          <Tab.Container defaultActiveKey="general">
            <SimpleBar>
              <header className="p-0">
                <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="general"
                      className="d-flex align-items-center py-3 px-x1 mb-0"
                    >
                      <FontAwesomeIcon
                        icon="stream"
                        className="text-600 tab-icon"
                      />
                      <h6 className="text-600 mb-0 ms-1">Geral</h6>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </header>
            </SimpleBar>
            <Tab.Content className="p-3">
              <Tab.Pane eventKey="general">
                <Settings />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </LayoutIntegration>
  );
};

export default MSPTalksIntegration;