import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const PauseReasonEditModal = ({ data, showModal, handleClose, isSuccess }) => {
  const [body, setBody] = useState({
    name: '',
    inactive: false
  });
  const [validated, setValidated] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState({});
  const [showError, setShowError] = useState(false);

  const api = useApi();
  const form = useRef(null);

  useEffect(() => {
    if (Object.keys(data).length != 0) {
      setBody(data);
      setValidated(false);
    }
  }, [data]);

  const onSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      const response = await api.pauseReasons.edit(data.id, body);
      if (!response.status) {
        isSuccess(true);
        setShowSuccess(true);
        handleClose();
      } else {
        setError(response);
        setShowError(true);
        isSuccess(false);
        handleClose();
      }
    }
  };

  return (
    <>
      <SuccessRequest
        message="Motivo de pausa editado com sucesso"
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo motivo de pausa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} ref={form}>
            <Form.Group className="mb-3" controlId="pauseReason.name">
              <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome"
                autoFocus
                value={body.name}
                onChange={e => setBody({ ...body, name: e.target.value })}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
            </Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Group>
              <Form.Check
                inline
                type="radio"
                label="Ativo"
                value="false"
                name="inactive"
                checked={!body.inactive}
                onChange={e => setBody({ ...body, inactive: e.target.value === "true" ? true : false })}
              />
              <Form.Check
                type="radio"
                inline
                label="Inativo"
                value="true"
                name="inactive"
                checked={body.inactive}
                onChange={e => setBody({ ...body, inactive: e.target.value === "true" ? true : false })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PauseReasonEditModal;