import { faRobot, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import TicketLogViewModal from 'components/modals/TicketLogViewModal';
import useApi from 'helpers/api';
import { formatCustomizerDate, formatHours, formatYear } from 'helpers/utils';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';

const TimelineTicket = forwardRef(({ ticketId }, ref) => {
  const api = useApi();
  const divDescriptionLog = useRef(null);

  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState({
    typeLog: 'ALL'
  });
  const [showMore, setShowMore] = useState(false);
  const [logView, setLogView] = useState({});
  const options = [
    { value: "ALL", label: "Todos" },
    { value: "INTERACTIONS", label: "Interações" },
    { value: "ATTACHMENTS", label: "Anexos" },
    { value: "SLA", label: "SLA" },
    { value: "COMMUNICATIONS", label: "Comunicação" },
    { value: "APPOINTMENTS", label: "Apontamentos" },
    { value: "INTEGRATIONS", label: "Integrações" }
  ];

  useEffect(() => {
    getLogs();
  }, [ticketId, filter]);

  useEffect(() => {
    if (logs.length > 0 && divDescriptionLog.current != null) {
      const imgs = divDescriptionLog.current.querySelectorAll('img');

      if (imgs) {
        imgs.forEach(img => {
          img.style.width = '100%';
          img.style.height = 'auto';
        });
      }
    }
  }, [logs]);

  useImperativeHandle(ref, () => ({
    getNewLogs: () => getLogs()
  }));

  const getLogs = async () => {
    if (ticketId != undefined) {
      const queryParams = new URLSearchParams(filter).toString();
      let response = await api.tickets.listLogs(ticketId, queryParams);
      setLogs(response);
    }
  };

  const handleViewLog = item => {
    setLogView(item);
    setShowMore(true);
  };

  const handleSelect = (eventKey) => {
    setFilter({ typeLog: eventKey });
  };

  return (
    <>
      <TicketLogViewModal data={logView} showModal={showMore} handleClose={() => setShowMore(false)} />
      <div className="timeline-vertical py-0">
        <Dropdown autoClose={false}>
          <Dropdown.Toggle variant="primary" id="dropdown-basic" className='rounded-pill'>
            Filtrar
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {options.map(option => (
              <Dropdown.Item
                key={option.value}
                eventKey={option.value}
                active={filter.typeLog === option.value}
                onClick={() => handleSelect(option.value)}
                className={filter.typeLog === option.value ? 'bg-primary' : ''}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {logs && logs.map((item, index) => {
          const {
            typeTicketLog,
            description,
            date,
            user
          } = item;
          return (
            <div
              key={index}
              className={classNames('timeline-item', {
                'timeline-item-start': index % 2 === 0,
                'timeline-item-end': index % 2 !== 0
              })}
            >
              <div className="timeline-icon icon-item icon-item-lg">
                <FontAwesomeIcon icon={faTags} className="fs-8" style={{ color: typeTicketLog.color }} />
              </div>
              <Row
                className={` ${index % 2 == 0 ? 'timeline-item-start' : 'timeline-item-end'
                  }`}
              >
                <Col lg={6} className="timeline-item-time">
                  <div>
                    <h6 className="mb-0 text-700">{formatYear(date)}</h6>
                    <p className="fs--2 text-500 font-sans-serif">{formatCustomizerDate(date)}</p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="timeline-item-content arrow-bg-white" style={{ maxHeight: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    <div
                      className="timeline-item-card bg-white dark__bg-1100 p-0">
                      <div className='p-3'>
                        <Flex alignItems="center" justifyContent="between" className="mb-2">
                          <h6 className="mb-2">{typeTicketLog.description}</h6>
                          <Button
                            variant="falcon-default"
                            size="sm"
                            onClick={() => handleViewLog(item)}
                          >
                            <FontAwesomeIcon icon="eye" size='sm' />
                          </Button>
                        </Flex>

                        <div ref={divDescriptionLog} className='overflow-x-hidden' dangerouslySetInnerHTML={{ __html: description }} />
                      </div>
                      <Flex wrap="wrap" alignItems="center" justifyContent="between" className="w-100 bg-100 p-3 position-sticky bottom-0">
                        <h6 className="mb-0 text-600 lh-base">
                          <FontAwesomeIcon
                            icon={['far', 'clock']}
                            className="me-1"
                          />
                          {formatHours(date)}
                        </h6>
                        {user
                          ?
                          <h6 className="mb-0 text-600 lh-base">
                            <FontAwesomeIcon
                              icon={'user'}
                              className="me-1"
                            />
                            {user.firstName}
                          </h6>
                          :
                          <h6 className="mb-0 text-600 lh-base">
                            <FontAwesomeIcon
                              icon={faRobot}
                              className="me-1"
                            />
                            Sistema
                          </h6>
                        }
                      </Flex>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </>
  );
});

export default TimelineTicket;